import AbstractProgressSupportingComponent from "./AbstractProgressSupportingComponent";

/**
 * Provide common functionality for all main pages
 */
export default class AbstractMainPage extends AbstractProgressSupportingComponent{
    static getPath(){
        throw new Error("getPath has to be implemented")
    }

    /**
     * Mark the current page so we can show the left menu accordingly
     * @param progress
     * @param value
     */
    /* istanbul ignore next */
    setCurrentPage(progress, value = null){
        if(progress.currentPage !== value) {
            progress.setCurrentPage(value);
        }else{
            console.log()
        }
    }
}