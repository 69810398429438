import React from "react";

export default class CassetteHelper{
    static getCassettesForLanguage(cassettes,lang){
        let finalCassettes = {};

        Object.keys(cassettes).forEach(key => {
            let cassette = cassettes[key];

            if(cassette['onlyForLangs']){
                if(cassette['onlyForLangs'].includes(lang.lang)){
                    finalCassettes[key] = cassette;
                }
            }else{
                finalCassettes[key] = cassette;
            }
        });

        return finalCassettes;
    }
}