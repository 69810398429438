import * as React from 'react'
import AbstractComponent from "../abstract_components/AbstractComponent";
import MenuSectionContentProvider from "../../content_providers/MenuSectionContentProvider";

const SectionsContext = React.createContext({sections:{}, sectionsLoading:true});

class SectionProvider extends AbstractComponent{
    state = {sections:{}, sectionsLoading: true}
    
    constructor(props) {
        super(props);
        this.load();
    }

    load(){
        // Download the sections for the menu
        MenuSectionContentProvider.getContent().then(sections => {
            this.setStateAccordingly("sections",sections);
            this.setStateAccordingly("sectionsLoading",false);
        });
    }

    render() {
        return (
            <SectionsContext.Provider value={{sections:this.state.sections, sectionsLoading: this.state.sectionsLoading}}>
                {this.props.children}
            </SectionsContext.Provider>
        );
    }
}

export {SectionProvider, SectionsContext}