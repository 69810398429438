import React from "react";
import {Link} from "react-router-dom";
import LeftMenuSection from "./LeftMenuSection";
import WelcomeScreen from "./main_pages/WelcomeScreen";
import AbstractProgressSupportingComponent from "./abstract_components/AbstractProgressSupportingComponent";
import LiMainMenuLink from "./LiMainMenuLink";
import TestScene from "./main_pages/TestScene";
import MissingDependencyOverlay from "./MissingDependencyOverlay";
import CONSTANTS from "../helpers/Contstants";
import {LanguageProvider} from "./context_providers/LanguageContext";

const TUTORIAL_KEY = "~tutorial";

class LeftMenu extends AbstractProgressSupportingComponent{

    state = {menuOpen:false, activeSection: "", languagesOpen: false};

    constructor(props){
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    getSectionID(){
        const location = window.location.hash?.replace("#","").split("?")[0] ?? ""

        if(location.startsWith("/section/")){
            return location.replace("/section/","")
        }else{
            return null;
        }
    }

    isCurrentSection(id, sections){
        let current = this.getSectionID();

        let subsections = sections[id]['subsections'];
        return !!subsections[current];
    }

    /**
     * Toggle the side menu
     */
    toggle(){
        this.setState(state => ({menuOpen: !state.menuOpen}));
        this.props.handler();
    }


    /* istanbul ignore next */
    renderWithProgress(progress, sections, sectionsLoading, lang) {
        let depIssue = progress.depIssue ? <MissingDependencyOverlay text={progress.depIssue}/> : "";
        this.getSectionID();
        return (
            <React.Fragment>
                <nav className={`nav ${this.state.menuOpen ? "nav-open" : ""}`}>
                    <div className="nav-content">
                        <div className="nav__img">
                            <Link to={WelcomeScreen.getPath()} id="welcome-button" onClick={()=>{
                                this.setCurrentPage(progress,"WELCOME")
                                this.setState({activeSection:null})
                                progress.setLastSlide(false);
                            }}>
                                <img src={CONSTANTS.image("side-nav-img.png")} alt="menu"/>
                                <p className="nav__img-desc">Kangaroo OMNI™ <br/> {lang.t("Practice Pump")} </p>
                            </Link>
                        </div>
                        <div className="nav__expand">
                            <div className="nav__expand__panel" onClick={this.toggle}>
                                <div className="menu-icon">
                                    <div className="bar1"/>
                                    <div className="bar2"/>
                                    <div className="bar3"/>
                                </div>
                                <p className="icon-open">{lang.t("Menu")} </p>
                                <p className="icon-close">{lang.t("Close")} </p>
                            </div>
                        </div>
                        <ul className="nav__list">
                            {Object.keys(sections).map((key, i) => (
                                <LeftMenuSection
                                    key={key}
                                    section={sections[key]}
                                    sectionID={key}
                                    active={this.state.activeSection === key || progress.currentPage === key || this.isCurrentSection(key, sections)}
                                    onClick={()=> {
                                        this.setState(({activeSection}) => ({activeSection: activeSection === key ? "" : key}));
                                }}/>
                            ))}
                            <LiMainMenuLink
                                active={this.state.activeSection === TUTORIAL_KEY}
                                isLearnt={progress.tutorialLearnt}
                                to={TestScene.getPath()}
                                onClick={()=>{
                                    this.setCurrentPage(progress,"TUTORIAL");
                                    if(this.state.activeSection !== TUTORIAL_KEY) {
                                        this.setState({activeSection:TUTORIAL_KEY});
                                    }
                                    progress.setLastSlide(false)
                                }}
                                label={lang.t("Test your knowledge")}/>
                        </ul>
                        <ul className="languages">
                            <li  className={`languages__popup collapsible ${this.state.languagesOpen ? "active" : ""}`} onClick={()=>this.setState(prev=> ({languagesOpen: !prev.languagesOpen}))}><span className="globe"/>{LanguageProvider.getLanguageName(lang.lang)}<span className="black-up-down-arrow"/></li>
                            <ul className={`languages__list ${this.state.languagesOpen ? "open" : ""}`}>
                                {lang.getLanguages().map((l,i)=> (
                                    <li  className={`languages__listitem lang_collapsible selected ${l === lang.lang ? "selected" : ""}`} key={i} onClick={()=>lang.setLang(l)}>{LanguageProvider.getLanguageName(l)}<span className="black-up-down-arrow"/></li>
                                ))}
                            </ul>
                        </ul>
                    </div>
                </nav>
                {depIssue}
            </React.Fragment>
        )
    }
}

export default LeftMenu