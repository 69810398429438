import React from "react";

export default class ActionLine extends React.Component{
    lineWidth = 4;
    verticalLineWidth = 2;
    lineStyle = {stroke:'rgb(151,151,151)',strokeWidth:this.lineWidth};
    verticalLineStyle = {stroke:'rgb(151,151,151)',strokeWidth:this.verticalLineWidth};


    /**
     * @param props {{start:DOMRect, end: DOMRect, isMobile: boolean, aroundMobile: boolean}}
     */
    constructor(props) {
        super(props);
    }

    render(){
        if(this.props.isMobile){
            return this.renderMobile();
        }else{
            return this.renderNonMobile();
        }
    }

    renderNonMobile(){
        let end     = this.props.end;
        let start   = this.props.start;

        let centerStartY = (start.y + start.height / 2) - this.lineWidth/2;
        let centerEndY = (end.y + end.height / 2) - this.lineWidth/2
        let centerEndX = (end.x + end.width / 2) + 1

        if(centerEndY >= start.top && centerEndY <= start.bottom){
            return this.renderStraightHLine(start.right,end.x,centerEndY)
        }else{
            return this.renderLLine(start.right, centerEndX, centerStartY, (centerStartY > end.bottom ? end.bottom : end.top))
        }
    }

    renderMobile(){
        let start   = this.props.start;
        let end     = this.props.end;
        let offset  = 6;

        let y1      = start.bottom + ((start.top-start.bottom) / 2);
        let y2      = end.bottom + ((end.top-end.bottom)/2);
        let x1      = start.left - offset;
        let x2      = x1 - offset;
        let x3      = (end.x + end.width / 2) - offset;

        let centerEndX = end.x + end.width / 2

        if(this.props.aroundMobile) {
            return this.renderCLine(x1, x2, x3, y1, y2)
        } else {
            return this.renderStraightVLine(centerEndX, start.bottom, end.top)
        }
    }

    //x1,x2,x3,y1,y2
    renderCLine(x1,x2,x3,y1,y2){
        let width  = Math.abs(x3 - x2);
        let height = Math.abs(y2 - y1);

        let lines;

        lines = (
            <React.Fragment>
                <line x1={x1} y1={0} x2={x2} y2={0} style={this.lineStyle}/>
                <line x1={x2} y1={0} x2={x2} y2={height} style={this.verticalLineStyle}/>
                <line x1={x2} y1={height} x2={x3} y2={height} style={this.lineStyle}/>
            </React.Fragment>
        );

        return (
            <svg role="line" className={this.props.extraClass} height={height} width={width} style={{position:'absolute',top:Math.min(y2, y1),left:x2}}>
                {lines}
            </svg>
        );
    }

    renderLLine(x1, x2, y1, y2){
        let width = x2 - x1
        let height = Math.abs(y2 - y1)

        let lines;
        if(y2 > y1){
            lines = (
                <React.Fragment>
                    <line x1={0} y1={0} x2={width} y2={0} style={this.lineStyle}/>
                    <line x1={width} y1={0} x2={width} y2={height} style={this.lineStyle}/>
                </React.Fragment>
            );
        }else{
            lines = (
                <React.Fragment>
                    <line x1={0} y1={height} x2={width} y2={height} style={this.lineStyle}/>
                    <line x1={width} y1={height} x2={width} y2={0} style={this.lineStyle}/>
                </React.Fragment>
            );
        }

        return (
          <svg role="line" className={this.props.extraClass} height={height} width={width} style={{position:'absolute',top:Math.min(y2, y1), left: x1}}>
              {lines}
          </svg>
        );
    }

    renderStraightVLine(x,y1,y2){
        let height = y2 - y1

        return (
            <svg role="line" className={this.props.extraClass} height={height} width={this.lineWidth} style={{position:'absolute',top:y1,left:x}}>
                <line x1="0" y1="0" x2="0" y2={height} style={this.lineStyle} />
            </svg>
        );
    }

    renderStraightHLine(x1,x2,y){
        let width = x2 - x1

        return (
            <svg role="line" className={this.props.extraClass} height={this.lineWidth} width={width} style={{position:'absolute',top:y,left:x1}}>
                <line x1="0" y1="0" x2={width} y2="0" style={this.lineStyle} />
            </svg>
        );
    }
}