import CONSTANTS from "./Contstants";

export default class ScreenImageHelper{
    /**
     * Load image of screen
     * @param image
     * @param lang
     * @return {string}
     */
    static getScreen(image, lang){
        image = image ? image : "green";
        return CONSTANTS.image(`screens/${lang.lang}/${image}${image.includes(".gif") || image.includes(".jpg") ? "" : ".png"}`);
    }
}