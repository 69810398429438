import React from "react";
import {LanguageContext} from "../context_providers/LanguageContext";
import AbstractComponent from "./AbstractComponent";

/**
 * Provides a render method that has direct access to the language context without the need of adding a consumer manually
 * every time
 */
export default class AbstractLanguageSupportingComponent extends AbstractComponent{
    render() {
        return (
            <LanguageContext.Consumer>
                {context =>
                    {
                        return this.renderWithLanguage(context)
                    }
                }
            </LanguageContext.Consumer>
        )
    }

    /**
     * Classes extending this class should implement their render logic in this function
     * @param lang
     */
    renderWithLanguage(lang){
        return (<div/>)
    }
}