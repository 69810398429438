import React from "react";
import AbstractLanguageSupportingComponent from "./abstract_components/AbstractLanguageSupportingComponent";

export default class IncreasingText extends AbstractLanguageSupportingComponent{

    initialValue(){
        if(this.info['starting']){
            return this.info['starting'];
        }else if(this.info["startingSettingKey"]){
            return this.props.settings[this.info["startingSettingKey"]];
        }else{
            return 0;
        }
    }

    initialValueProcessed(){
        return (this.info['startingMultiplier'] ?? 1) * this.initialValue();
    }

    constructor(props){
        super(props);
        this.tick = this.tick.bind(this);

        this.info = props['onscreenNumber'];
        this.state = {value: this.initialValueProcessed()}

        if(this.info['rate'])
            this.timoutID = setInterval(this.tick, this.info['rate']);
    }

    /* istanbul ignore next */
    componentWillUnmount() {
        if(this.timoutID)
            clearInterval(this.timoutID);
    }

    /* istanbul ignore next */
    tick(){
        let instance = this;
        instance.setState(prev => ({value: (instance.info["resetkey"] !== undefined && prev.value + instance.info['increaseWith'] > instance.props.settings[instance.info["resetkey"]]) ? 1 : prev.value + instance.info['increaseWith'] }),() => {
            if(this.props.onChange){
                this.props.onChange(this.state.value)
            }
            if(instance.info["limit"] !== undefined && this.state.value === instance.info["limit"]){
                clearTimeout(this.timoutID)
            }
            if(instance.info["limit_max"] !== undefined && this.state.value === instance.info["limit_max"]){
                clearTimeout(this.timoutID)
            }
        })
    }

    hoursToMinutes(hours){
        return hours*60;
    }

    toMinutes(seconds){
        const minutes = Math.floor(seconds / 60)
        const sec = seconds % 60
        const secText = sec < 10 ? `0${sec}` : `${sec}`

        return `${minutes}:${secText}`
    }

    getValueText(lang){
        const value = this.props.value !== undefined ? this.props.value : this.state.value

        if(this.info['format'] && lang){

            let final = '';
            if(this.info['format'][lang.lang]) {
                final = lang.t(this.info['format'][lang.lang]).replace("%VALUE%",value)
            } else if(this.info['format']['all']){
                final = lang.t(this.info['format']['all']).replace("%VALUE%",value)
            } else {
                final = lang.t(this.info['format']).replace("%VALUE%",value)
            }

            final = final.replace("%VALUE_MINUTE%",this.toMinutes(value))
            final = final.replace("%VALUE_HOURS_TO_MINUTES%",this.hoursToMinutes(value))

            const settings = this.props.settings ?? {}

            /* istanbul ignore next */
            Object.keys(settings).forEach(key => {
                final = final.replace(`%${key}%`,settings[key])
            })

            return final
        }else{
            return value
        }
    }

    renderWithLanguage(lang){
        return (
            <div className={`${this.info.position} on-screen-text`}>
                {this.getValueText(lang)}
            </div>
        );
    }
}