import * as React from 'react'
import AbstractComponent from "../abstract_components/AbstractComponent";
import cookies from "../../cookie"

/* istanbul ignore next */
const ProgressContext = React.createContext({
    currentPage:null,
    progress:{},
    hotspotsProgress: {},
    actionsProgress:{},
    depIssue:null,
    /* istanbul ignore next */
    tutorialLearnt:false,
    /* istanbul ignore next */
    selectedCassette: null,
    /* istanbul ignore next */
    selectCassette:(id)=>false,
    /* istanbul ignore next */
    setDependencyIssue:(issue)=>false,
    /* istanbul ignore next */
    isSubSectionCompleted:(sec,subSec)=>false,
    /* istanbul ignore next */
    setSubsectionCompleted: (sec,subSec) => false,
    /* istanbul ignore next */
    setCurrentPage: (page) => false
});

const PROGRESS_KEY = "progress";
const HP_PROGRESS_KEY = "hp_progress";
const AC_PROGRESS_KEY = "ac_progress";
const TUTORIAL_LEARNT_KEY = "tutorial_learnt";
const CASSETTE_KEY = "cassette";

class ProgressProvider extends AbstractComponent{

    constructor(props) {
        super(props);

        this.state = {
            progress:this.getProgress(),
            cassette:this.getCassette(),
            currentPage:null,
            hotspotsProgress:this.getHotspotsProgress(),
            actionsProgress: this.getActionsProgress(),
            depIssue: null,
            isLastSlide:false,
            tutorialLearnt:cookies.get(TUTORIAL_LEARNT_KEY)
        }

        this.setLastSlide = this.setLastSlide.bind(this);
        this.setTutorialLearnt = this.setTutorialLearnt.bind(this);
        this.setCassette = this.setCassette.bind(this);
        this.isSubSectionCompleted = this.isSubSectionCompleted.bind(this);
        this.setSubsectionCompleted = this.setSubsectionCompleted.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.isActionPerformed = this.isActionPerformed.bind(this);
        this.setActionPerformed = this.setActionPerformed.bind(this);
        this.isHotspotOpened = this.isHotspotOpened.bind(this);
        this.setHotspotOpened = this.setHotspotOpened.bind(this);
        this.setProgress = this.setProgress.bind(this);
    }

    getCassette(){
        return cookies.get(CASSETTE_KEY);
    }

    setCassette(value){
        cookies.set(CASSETTE_KEY,value);
        this.setState({cassette:value})
    }

    /**
     * Load progress from cookies and init if needed.
     * @return {null}
     */
    getProgress(){
        return this.getCookieProgress(PROGRESS_KEY);
    }

    /**
     * Load hp progress from cookies and init if needed.
     * @return {*}
     */
    getHotspotsProgress(){
        return this.getCookieProgress(HP_PROGRESS_KEY);
    }

    /**
     * Load actions progress from cookies and init if needed
     * @return {*}
     */
    getActionsProgress(){
        return this.getCookieProgress(AC_PROGRESS_KEY);
    }

    /**
     * Get progress from cookies or init if needed
     * @param key
     * @return {*}
     */
    getCookieProgress(key){
        let progress = cookies.get(key);

        if(!progress){
            progress = {};
        }else{
            progress = JSON.parse(progress);
        }

        return progress;
    }

    /**
     * Was the hotspot opened before
     * @param sectionID
     * @param subsectionID
     * @param slideIndex
     * @param hotspotIndex
     * @return {boolean|*}
     */
    isHotspotOpened(sectionID, subsectionID, slideIndex, hotspotIndex){
        return this.checkProgress(this.state.hotspotsProgress, sectionID, subsectionID, slideIndex, hotspotIndex);
    }

    /**
     * Was the action completed before
     * @param sectionID
     * @param subsectionID
     * @param slideIndex
     * @param actionIndex
     * @return {boolean|*}
     */
    isActionPerformed(sectionID, subsectionID, slideIndex, actionIndex){
        return this.checkProgress(this.state.actionsProgress,sectionID, subsectionID,slideIndex, actionIndex);
    }

    /**
     * Check if there is any date and return it. Default to false
     * @param progress
     * @param sectionID
     * @param subsectionID
     * @param slideIndex
     * @param index
     * @return {boolean|*}
     */
    checkProgress(progress, sectionID, subsectionID,slideIndex, index){
        if(progress[sectionID]){
            if(progress[sectionID][subsectionID]){
                if(progress[sectionID][subsectionID][slideIndex]) {
                    if (progress[sectionID][subsectionID][slideIndex][index]) {
                        return !!progress[sectionID][subsectionID][slideIndex][index];
                    }
                }
            }
        }

        return false;
    }

    /**
     *
     * @param sectionID
     * @param subsectionID
     * @param slideIndex
     * @param hotspotIndex
     */
    setHotspotOpened(sectionID, subsectionID,slideIndex, hotspotIndex){
        let hps = this.setProgressHappened(this.state.hotspotsProgress,sectionID,subsectionID,slideIndex,hotspotIndex);
        this.setHotspotProgress(hps);
    }

    /**
     *
     * @param sectionID
     * @param subsectionID
     * @param slideIndex
     * @param actionIndex
     */
    setActionPerformed(sectionID, subsectionID,slideIndex, actionIndex){
        let acs = this.setProgressHappened(this.state.actionsProgress,sectionID,subsectionID,slideIndex,actionIndex);
        this.setActionsProgress(acs);
    }

    setProgressHappened(progress, sectionID, subsectionID,slideIndex, index) {
        if (!progress[sectionID])
            progress[sectionID] = {};

        if (!progress[sectionID][subsectionID])
            progress[sectionID][subsectionID] = {};

        if(!progress[sectionID][subsectionID][slideIndex])
            progress[sectionID][subsectionID][slideIndex] = {};

        progress[sectionID][subsectionID][slideIndex][index] = true;

        return progress;
    }

    /**
     * Was the section completed?
     * @param sectionID
     * @param subsectionID
     * @return {boolean|*}
     */
    /* istanbul ignore next */
    isSubSectionCompleted(sectionID, subsectionID){
        if(this.state.progress[sectionID]){
            if(this.state.progress[sectionID][subsectionID]){
                return this.state.progress[sectionID][subsectionID];
            }
        }

        return false;
    }

    /**
     * Mark subsection completed
     * @param sectionID
     * @param subsectionID
     */
    setSubsectionCompleted(sectionID, subsectionID){
        let progress = this.state.progress;

        if(!progress[sectionID])
            progress[sectionID] = {};

        progress[sectionID][subsectionID] = true;

        this.setProgress(progress);
    }

    /**
     * Save the progress to cookies
     */
    setProgress(progress){
        this.setState({progress:progress})
        cookies.set(PROGRESS_KEY, progress);
    }

    /**
     * Save hp progress to cookies
     * @param progress
     */
    setHotspotProgress(progress){
        this.setState({hotspotsProgress:progress});
        cookies.set(HP_PROGRESS_KEY, progress);
    }

    /**
     * Save actions progress to cookies
     * @param progress
     */
    setActionsProgress(progress){
        this.setState({actionsProgress:progress});
        cookies.set(AC_PROGRESS_KEY, progress);
    }

    /**
     * Mark the current page
     * @param page
     */
    setCurrentPage(page){
        this.setState({currentPage:page});
    }

    setTutorialLearnt(value){
        this.setState({tutorialLearnt:value});
        cookies.set(TUTORIAL_LEARNT_KEY, value);
    }

    setLastSlide(value){
        this.setState({isLastSlide:value})
    }

    /**
     *
     * @return {JSX.Element}
     */
    render() {
        return (
            <ProgressContext.Provider value={
                {
                    isLastSlide: this.state.isLastSlide,
                    setLastSlide: this.setLastSlide,
                    cassette:this.state.cassette,
                    setCassette:this.setCassette,
                    currentPage:this.state.currentPage,
                    progress:this.state.progress,
                    isSubSectionCompleted:this.isSubSectionCompleted,
                    setSubsectionCompleted: this.setSubsectionCompleted,
                    setCurrentPage: this.setCurrentPage,
                    setHotspotOpened: this.setHotspotOpened,
                    isHotspotOpened: this.isHotspotOpened,
                    setActionPerformed:this.setActionPerformed,
                    isActionPerformed:this.isActionPerformed,
                    setTutorialLearnt:this.setTutorialLearnt,
                    depIssue: this.state.depIssue,
                    /* istanbul ignore next */
                    tutorialLearnt: this.state.tutorialLearnt,
                    /* istanbul ignore next */
                    setDependencyIssue: (issue) => this.setState({depIssue: issue})
                }
            }>
                {this.props.children}
            </ProgressContext.Provider>
        );
    }
}

export {ProgressContext, ProgressProvider};