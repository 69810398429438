import React from "react";

/**
 * Abstract base for all components of the app
 */
export default class AbstractComponent extends React.Component{
    mounted = false

    constructor(props) {
        super(props);

        this.setStateAccordingly = this.setStateAccordingly.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
    }

    /**
     * If the component is already mounted, set the state with setState, otherwise set it just as a normal variable
     */
    setStateAccordingly(key, value){
        if(this.mounted) {
            let obj = {};
            obj[key] = value;
            this.setState(obj);
        }
        else
            this.state[key] = value;
    }
}