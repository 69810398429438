import React from "react";
import Loading from "./Loading";

class InitialLoading extends React.Component{
    render() {
        return (
            <Loading/>
        )
    }
}

export default InitialLoading