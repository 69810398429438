import * as React from 'react'
import CONSTANTS from "../../helpers/Contstants";
import AbstractComponent from "../abstract_components/AbstractComponent";
import cookies from "../../cookie"

const LanguageContext = React.createContext({translations:{}, t:((s) => s), lang:"en"});
const LANGUAGE_KEY = "language";

/**
 * Normalize language key
 * @param val {string}
 * @return {string}
 */
const normalizeKey = (val) => val.trim().toLowerCase()

/**
 * Normalize translation value
 * @param val {string}
 * @return {string}
 */
const normalizeValue = (val) => val.trim()

class LanguageProvider extends AbstractComponent{
    state = {
        languages:{},
        initialized: false,
        lang:this.getLang()
    };

    constructor(props) {
        super(props);
        this.load();

        this.t = this.t.bind(this);
        this.setLang = this.setLang.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
        this.parseLanguages = this.parseLanguages.bind(this);
    }

    static getLanguageName(lang){
        switch (lang){
            case "fr":
                return "FR-CA"
            case "freu":
                return "FR"
            case "esam":
                return "ES-AM"
            case "ptbr":
                return "PT-BR"
            default:
                return lang.toUpperCase()
        }
    }

    getLanguages(){
        let langs = Object.keys(this.state.languages);
        langs.unshift("en");

        return langs;
    }

    getLang(){
        let lang = cookies.get(LANGUAGE_KEY);
        if(lang)
            return lang;
        return "en";
    }

    /**
     * Set the language
     * @param lang
     */
    setLang(lang){
        this.setState({lang});
        cookies.set(LANGUAGE_KEY,lang,{SameSite:"none",secure:true});
    }

    /**
     * Translate string to language. If language was not found, the same string will be returned
     * @param string
     */
    t(string){
        const normalizedKey = normalizeKey(string);

        /* istanbul ignore next */
        if(this.state.languages[this.state.lang] !== undefined && this.state.languages[this.state.lang][normalizedKey] !== undefined){
            return this.state.languages[this.state.lang][normalizedKey];
        }

        return string;
    }

    /**
     * We need to convert english keys into lowercase, and we need to trim both keys and translated text
     * This will help to eliminate the need for duplicate translations in many cases
     * @param data {{[lang:string]:{[en:string]:string}}}
     * @return Normalized data
     */
    parseLanguages(data){
        let newData = {};

        for (const lang in data){
            let languageData = {};

            for (const en in data[lang]){
                const normalizedKey = normalizeKey(en)
                languageData[normalizedKey] = normalizeValue(data[lang][en]);
            }

            newData[lang] = languageData;
        }

        return newData
    }

    /**
     * Load languages from server.
     * We can't import this because we want to be able to edit any content without rebuilding the app.
     */
    load(){
        fetch(CONSTANTS.languages_url)
            .then(r => {
                if(r.ok){
                    return r.text()
                }
                /* istanbul ignore next */
                return null;
             })
            .then(data => {
                return JSON.parse(data)
            })
            .then(data => {
                const parsed = this.parseLanguages(data)
                this.setStateAccordingly('languages',parsed);
                this.setStateAccordingly('initialized',true);
            })
            .catch(e => {
                console.log(e);
                this.setStateAccordingly('languages', {});
            })
    }

    render() {
        return (
            <LanguageContext.Provider value={{getLanguages: this.getLanguages, translations: this.state.languages, lang: this.state.lang, t: this.t, initialized: this.state.initialized, setLang:this.setLang}}>
                {this.props.children}
            </LanguageContext.Provider>
        );
    }
}

export {LanguageProvider, LanguageContext}
