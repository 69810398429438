/**
 * Get a cookie
 * @param key
 * @param defaultValue
 * @returns {*}
 */
export function get(key, defaultValue){
    const value = localStorage.getItem(key)

    return value ?? defaultValue
}

/**
 * Set cookie. Automatically sets SameSite to none and secure to true but can be overwritten with params
 * @param key
 * @param value
 */
export function set(key, value){
    let v;
    if(typeof value !== "string")
        v = JSON.stringify(value)
    else
        v = value
    localStorage.setItem(key,v);
}

export default {
    get,
    set
}