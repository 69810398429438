import AbstractContentProvider from "./AbstractContentProvider";
import CONSTANTS from "../helpers/Contstants";

export default class SectionContentProvider extends AbstractContentProvider{
    static getURL() {
        return '';
    }

    static async getSection(id){
        return await this.getContentFromURL(CONSTANTS.section_url(id))
    }
}