import React from "react";
import Loading from "../Loading";
import {Link} from "react-router-dom";
import WelcomeScreenContentProvider from "../../content_providers/WelcomeScreenContentProvider";
import AbstractMainPage from "../abstract_components/AbstractMainPage";
import CONSTANTS from "../../helpers/Contstants";
import ScreenImageHelper from "../../helpers/ScreenImageHelper";

export default class WelcomeScreen extends AbstractMainPage{

    state = {loading:true}
    headline = ""
    body = ""
    buttonText = ""

    constructor(props) {
        super(props);

        WelcomeScreenContentProvider.getContent().then(json => {
            this.headline = json['headline'];
            this.body = json['body'];
            this.buttonText = json['button_text'];

            this.setStateAccordingly('loading',false);
        });
    }

    static getPath(){
        return "/";
    }


    /**
     * Render the components
     * @param progress
     * @param lang
     * @param sections
     * @param sectionsLoading
     * @returns {JSX.Element}
     */
    renderWithProgress(progress, sections, sectionsLoading, lang) {
        if(this.state.loading || sectionsLoading) return <Loading/>

        let first = this.getFirstID(sections);
        let firstButton;
        if(first){
            firstButton = <Link to={`/section/${first}`}>{lang.t(this.buttonText)}</Link>;
        }else{
            firstButton = "";
        }

        return (
            <div className="main__content">
                <div className="logo">
                    <img src={CONSTANTS.image("cardinalhealth-logo.png")} alt="logo"/>
                </div>
                <h1>{lang.t(this.headline)}</h1>
                <div className="body-copy">
                    {this.body.map((copy, i) => (<p key={`par-${i}`}>{lang.t(copy)}</p>))}
                </div>
                <div className="omnipump mobile-hidden omnipump-welcome">
                    <div className="omnipump-machine">
                        <img src={CONSTANTS.image("OmniPumpFront_OffButtons.png")} alt="machine"/>
                    </div>

                    <div className="omnipump-screen">
                        <img src={ScreenImageHelper.getScreen("black", lang)} alt="screen"/>
                    </div>
                </div>

                <div className="button button-main-red">
                    <div className="button__text">
                        {firstButton}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Get the ID of the first subsection to render a navigation link to
     * @param sections
     */
    getFirstID(sections){
        let keys = Object.keys(sections);

        if(keys.length > 0) {
            let firstKey = keys[0];
            let firstSubsections = sections[firstKey]['subsections'];

            if(firstSubsections && Object.keys(firstSubsections).length > 0){
                return Object.keys(firstSubsections)[0];
            }
        }else{
            // test
        }

        return null;
    }
}