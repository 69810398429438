import AbstractLanguageSupportingComponent from "./abstract_components/AbstractLanguageSupportingComponent";
import TutorialOverlaySlide from "./TutorialOverlaySlide";

export default class TutorialOverlay extends AbstractLanguageSupportingComponent{

    /**
     * @param props {{slides:Array, title:string}}
     */
    constructor(props) {
        super(props);
        this.state = {activeSlide: 0};

        this.nextSlide = this.nextSlide.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
        this.setDocumentClass = this.setDocumentClass.bind(this);
    }

    setDocumentClass(){
        if(this.state.activeSlide === 4){
            document.body.classList.add("overlay-4")
        }else{
            document.body.classList.remove("overlay-4")
        }
    }

    nextSlide(){
        this.setState({activeSlide:this.state.activeSlide+1}, this.setDocumentClass)
    }

    prevSlide(){
        this.setState({activeSlide:this.state.activeSlide-1},this.setDocumentClass)
    }

    renderWithLanguage(lang) {
        const cls = this.props.slides ? (this.state.activeSlide === this.props.slides.length - 1 ? "last" : "inbetween") : "last"

        return (
            <div className="guide">
                {this.props.slides && this.props.slides.length > 0 &&
                    <div className="guide__content clearfix">
                        <span className="close-btn" onClick={this.props.onClose}>X {lang.t("Close")}</span>
                        <TutorialOverlaySlide
                            slide={this.props.slides[this.state.activeSlide]}
                            title={this.props.title}
                            cls={this.state.activeSlide === 0 ? "first" : cls}
                            onPrev={this.state.activeSlide > 0 ? this.prevSlide : null}
                            onNext={this.state.activeSlide < this.props.slides.length - 1 ? this.nextSlide : null}
                            onFinish={this.state.activeSlide === this.props.slides.length - 1 ? this.props.onClose : null}
                        />
                        <div className="dots">
                            {this.props.slides.map((_, i) => (
                                <span key={i} className={`dot ${this.state.activeSlide === i ? "active" : ""}`}
                                      onClick={() => this.setState({activeSlide: i},this.setDocumentClass)}/>))}
                        </div>
                    </div>
                }
            </div>
        );
    }
}