import CassetteSelectionScreen from "./main_pages/CassetteSelectionScreen";

const CASSETTE_SCREEN = "cassette";

export default class PreBuiltHelper{

    static getPreBuilt(id, ref, forceUpdate){
        if(id === CASSETTE_SCREEN){
            return <CassetteSelectionScreen ref={ref} forceUpdate={forceUpdate}/>
        }else{
            return "";
        }
    }
}