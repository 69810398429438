import React from "react";
import {Link, useRouteMatch} from "react-router-dom";

export default function LiMainMenuLink({ label, to, isLearnt, onClick, active }){
    let match = useRouteMatch({
        path: to,
        exact: true
    });

    let link = to ? <Link to={to}>{label}</Link> : <a href="/" onClick={(e)=>e.preventDefault()}>{label}</a>;
    return (
        <li onClick={onClick} className={`nav__listitem collapsible ${match || active ? "active" : ""} ${isLearnt ? "learnt" : ""}`}>
            <span className="checkmark"/>
            {link}
            <span className="black-right-arrow"/>
        </li>
    )
}