import AbstractLanguageSupportingComponent from "./abstract_components/AbstractLanguageSupportingComponent";
import Button from "./Button";
import React from "react";

export default class TutorialOverlaySlide extends AbstractLanguageSupportingComponent{

    /**
     * @param props {{onPrev:function, onNext:function, onFinish:function, slide: {}}}
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderWithLanguage(lang) {
        let leftButton
        if(this.props.onPrev){
            leftButton = <Button noArrow={!this.props.onNext} text={this.props.onNext ?lang.t( "Previous") : lang.t("Finish")} next={false} onClick={this.props.onNext ? this.props.onPrev : this.props.onFinish}/>
        }else{
            leftButton = ""
        }

        let rightButton
        if(this.props.onNext){
            rightButton = <Button text={this.props.onPrev ? lang.t("Next") : lang.t("Begin")} next={true} onClick={this.props.onNext}/>
        }else{
            rightButton = ""
        }

        let hotspot = "";
        if(this.props.slide && this.props.slide.button){
            hotspot = (<Button next={true}/>)
        }else{
            hotspot = <span className="hotspot main-red"/>
        }

        if(this.props.slide && this.props.slide.noAction){
            hotspot = "";
        }

        let tip = "";
        if(this.props.slide){
            tip = (
                <div className={`tip ${this.props.slide.position}`}>
                    <p>{this.props.slide.copy}</p>
                    {this.props.slide.noAction ? null : 
                    <>
                        <div>
                            {hotspot}
                        </div> 
                    </>}
                </div>
            );
        }
        const title = this.props.slide && this.props.slide.title ? this.props.slide.title : null
        return (
            <div className={`guide__content__slides fade ${this.props.cls}`}>
                <h4 dangerouslySetInnerHTML={{__html: lang.t(title ?? this.props.title)}}/>
                <div className="guide-buttons">
                    {leftButton}
                    {rightButton}
                </div>
                {tip}
            </div>
        )
    }
}