import AbstractSectionsSupportingComponent from "./AbstractSectionsSupportingComponent";
import {ProgressContext} from "../context_providers/ProgressContext";

export default class AbstractProgressSupportingComponent extends AbstractSectionsSupportingComponent{

    renderWithSections(sections, sectionsLoading,lang){
        return (
          <ProgressContext.Consumer>
              {context => {return this.renderWithProgress(context,sections, sectionsLoading, lang)}}
          </ProgressContext.Consumer>
        );
    }

    /**
     * Override method to render with progress provided
     * @param progress
     * @param sections
     * @param sectionsLoading
     * @param lang
     * @return {JSX.Element}
     */
    renderWithProgress(progress, sections, sectionsLoading, lang){
        return <div/>
    }

    /* istanbul ignore next */
    setCurrentPage(progress, value = null){
        if(progress.currentPage !== value) {
            progress.setCurrentPage(value);
        }
    }
}