import AbstractLanguageSupportingComponent from "./abstract_components/AbstractLanguageSupportingComponent";
import React from "react";

export default class ExtraInstructions extends AbstractLanguageSupportingComponent{

    getColor(){
        return this.props.info['color']
    }

    getImage(){
        return this.props.info['img']
    }

    renderWithLanguage(lang) {
        let image = "";
        let url = this.getImage();
        let needsExtraClass = false;

        if(url){
            image = <img src={`/images/${url}`} alt="instruct" className="top-image"/>;
            needsExtraClass = true;
        }

        return (
            <>
                <div ref={this.props.setRef} className={`color-screen ${needsExtraClass ? "with-image" : ""}`} style={this.getColor() ? {borderBottom: '6px solid '+this.getColor()}: {}}>
                    {image}
                    <div className="color-screen-box">
                        { this.props.info.title && 
                        <p><strong>{lang.t(this.props.info.title)}</strong></p>
                        }
                        <p dangerouslySetInnerHTML={{__html:lang.t(this.props.info.body)}}/>
                    </div>
                </div>
                {this.props.info.instruction ? 
                <div className="instruction_below">
                    <p>{this.props.info.instruction}</p>
                </div> : null}
            </>
        );
    }
}