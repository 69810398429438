import AbstractProgressSupportingComponent from "./abstract_components/AbstractProgressSupportingComponent";

export default class MissingDependencyOverlay extends AbstractProgressSupportingComponent{

    renderWithProgress(progress, sections, sectionsLoading, lang) {
        return (
            <div className={"dep-issue"}>
                <div className={"dep-issue-wrapper"}>
                    <p>{lang.t(this.props.text)}</p>
                    <a href="/" onClick={(e)=>{
                        e.preventDefault();
                        progress.setDependencyIssue(null);
                    }}>X {lang.t('Close')}</a>
                </div>
            </div>
        );
    }
}