import React from "react";
import AbstractLanguageSupportingComponent from "./abstract_components/AbstractLanguageSupportingComponent";

export default class Button extends AbstractLanguageSupportingComponent{

    isActive(){
        return (this.props.active !== false);
    }

    getLeftArrow(){
        return this.props['leftArrow'] ? this.props['leftArrow'] : "/images/left-arrow.png";
    }

    getRightArrow(){
        return this.props['rightArrow'] ? this.props['rightArrow'] : "/images/right-arrow.png";
    }

    renderWithLanguage(lang) {
        let image = this.props.next ? this.getRightArrow() : this.getLeftArrow();
        let noTextTitle = this.props.next ? lang.t("Next") : lang.t("Previous")
        let nonTranslatedText = this.props.text ? this.props.text : noTextTitle
        let text  = lang.t(nonTranslatedText)

        let button;
        if(this.props.next) {
            button = (<React.Fragment>{text}{this.props.noArrow ? "" :<img src={image} alt="arrow"/>}</React.Fragment>)
        }else{
            button = (<React.Fragment>{this.props.noArrow ? "" :<img src={image} alt="arrow"/>}{text}</React.Fragment>)
        }

        let colorClass;
        if(this.props.white){
            colorClass = 'button-white'
        }else if(this.props.transparent){
            colorClass = 'button-transparent'
        }else{
            colorClass = 'button-guide-red'
        }

        return (
            <div className={`button ${colorClass} ${ this.isActive() ? 'button-active' : ""}`}>
                <div className="button__text">
                    {/* istanbul ignore next */}
                    <a href="/" className={this.props.next ? "next":"prev"} onClick={(e)=>{
                        /* istanbul ignore next */
                        e.preventDefault();
                        /* istanbul ignore next */
                        if(this.props.onClick && this.isActive()){
                            this.props.onClick(e)
                        }
                    }}>
                        {button}
                    </a>
                </div>
            </div>
        );
    }
}