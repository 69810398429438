import AbstractLanguageSupportingComponent from "./AbstractLanguageSupportingComponent";
import React from "react";
import {SectionsContext} from "../context_providers/SectionsContext";

export default class AbstractSectionsSupportingComponent extends AbstractLanguageSupportingComponent{
    renderWithLanguage(lang) {
        return (
            <SectionsContext.Consumer>
                {context => {return this.renderWithSections(context.sections, context.sectionsLoading, lang)}}
            </SectionsContext.Consumer>
        )
    }

    /**
     * Classes extending this class should implement their render logic in this function
     * @param sections
     * @param sectionsLoading
     * @param lang
     */
    renderWithSections(sections, sectionsLoading,lang){
        return (<div/>)
    }
}