import AbstractMainPage from "../abstract_components/AbstractMainPage";
import React from "react";
import TutorialOverlay from "../TutorialOverlay";
import CONSTANTS from "../../helpers/Contstants";
import GuideContentProvider from "../../content_providers/GuideContentProvider";
import SectionContentProvider from "../../content_providers/SectionContentProvider";
import Loading from "../Loading";
import HotspotModal from "../HotspotModal";
import Button from "../Button";
import SectionEndScreen from "../SectionEndScreen";
import ExtraInstructions from "../ExtraInstructions";
import PreBuiltHelper from "../PreBuiltHelper";
import TestScene from "./TestScene";
import ScreenImageHelper from "../../helpers/ScreenImageHelper";
import LedImagesHelper from "../../helpers/LedImagesHelper";
import AudioHelper from "../../helpers/AudioHelper";
import ActionLine from "../ActionLine";
import {offsetSVGParent} from "../../helpers/CoordonateHelper";

import cookies from "../../cookie"
import "../../style/slick.css";
import "../../style/slick-theme.css";
import CassetteImageHelper from "../../helpers/CassetteImageHelper";
import {generateOnScreenNumber, checkFeedRules} from "../../helpers/helpers";

export default class SectionScreen extends AbstractMainPage{

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    constructor(props) {
        super(props);

        this.isLastScreen = this.isLastScreen.bind(this);
        this.closeTutorial = this.closeTutorial.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.isBackImage = this.isBackImage.bind(this);
        this.handleAction = this.handleAction.bind(this);
        // this.hashChanged = this.hashChanged.bind(this);
        this.getActiveSlideQueryParam = this.getActiveSlideQueryParam.bind(this)
        this.getAction = this.getAction.bind(this);
        this.setExtraRef = this.setExtraRef.bind(this);

        this.timeoutAction = this.timeoutAction.bind(this);

        this.goNext = this.goNext.bind(this);
        this.goPrev = this.goPrev.bind(this);

        this.initScreen(props)

        this.prebuiltRef = React.createRef();
        this.audio = null;
    }

    initScreen(props, shouldSetState = false){
        /**
         * THIS IS A HACK BECAUSE THERE IS NO GUARANTEE THAT THERE WILL BE A parts IN THE JSON.
         * but you know how it works.. time is time..
         */
        if(cookies.get(CONSTANTS.first_run_key) !== "false" && props.match.url !== "/section/parts"){
            props.history?.push("/section/parts")
        }

        const activeSlide = this.getActiveSlideQueryParam()

        let state = {
            showTutorial: cookies.get(CONSTANTS.first_run_key) !== "false",
            guide: {title: "", slides: []},
            section: props['section'] ? props['section'] : null,
            activeSlide: activeSlide,
            activePopup: props['activePopup'] ? props["activePopup"] : null,
            windowWidth: window.innerWidth,
            actionRefs: {},
            extraRef:{},
            nextTimer: 0,
            actionTimeoutID: props['actionTimeoutID'] ? props['actionTimeoutID'] : '',
            settings:{
                feed_rate: 15,
                feed_number: 1,
                feed_volume: 200,
                feed_interval: 12,
                flush_volume: 300,
                flush_interval: 2,
                pause_duration: 5,
                feed_rate_changed: false,
                feed_interval_changed: false,
                feed_volume_changed: false
            }
        };

        if(shouldSetState){
            this.setState(state);
        }else {
            this.state = state;
        }

        this.sectionID = props.match.params.sectionID
        this.timeoutID = -1

        GuideContentProvider.getContent().then(json => {
            this.setStateAccordingly('guide',json);
        })

        SectionContentProvider.getSection(this.sectionID).then(json => {
            this.setStateAccordingly("section",json);
        })
    }

    componentDidMount() {
        super.componentDidMount()
        window.addEventListener("resize", this.handleResize);
        // window.addEventListener("hashchange", this.hashChanged);
    }

    /* istanbul ignore next */
    componentWillUnMount() {
        super.componentWillUnmount()
        window.removeEventListener("resize", this.handleResize);
        // window.removeEventListener("hashchange", this.hashChanged);
    }

    activeSlide(progress){
        const slides = this.getSlides(progress)
        if(slides.length > 0)
            return slides[this.state.activeSlide];
        return {title: ""}
    }

    getActiveSlideQueryParam(){
        const search = new URLSearchParams(this.props.location?.search)
        return Number(search.get("slide") ?? 0)
    }

    // hashChanged(){
    //     const toPage = Number(window.location.hash.replace("#",""))
    //     this.setState({activeSlide:toPage})
    // }


    getSlides(progress){
        if(!(this.state.section && this.state.section.slides)) return [];

        if(Array.isArray(this.state.section.slides)){
            return this.state.section.slides;
        }else{
            let cassette = false;
            if(progress && progress.cassette) {
                cassette = progress.cassette;
            }
            let slides;
            if(cassette)
                slides = this.state.section.slides[cassette];
            else
                slides = this.state.section.slides[Object.keys(this.state.section.slides)[0]];

            if(Array.isArray(slides)){
                return slides;
            }else{
                return this.state.section["slides_ref"][slides];
            }
        }
    }

    activeHotspot(progress){
        if(this.state.activePopup !== null && this.activeSlide(progress)['hotspots'])
            return this.activeSlide(progress)['hotspots'][this.state.activePopup];
        else
            return null;
    }

    getMachineImage(progress){
        let type = this.activeSlide(progress)['machine_type'];

        if(type === "back" || type === "front" || !type){
            return CONSTANTS.image(type === "back" ? "OmniPumpBack.png" : "OmniPumpFront.png");
        }else{
            return CONSTANTS.image(type);
        }
    }

    isMachineImage(progress){
        let type = this.activeSlide(progress)['machine_type'];
        return type === "back" || type === "front" || !type;
    }

    isBackImage(progress){
        return this.activeSlide(progress)['machine_type'] === "back";
    }

    closePopup(){
        this.setState({activePopup:null})
    }

    /**
     * Update the component when navigating between scenes
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props && this.props.match.params.sectionID !== prevProps.match.params.sectionID){
            this.initScreen(this.props,true)
        }
        if(this.state && this.state.settings && prevState && prevState.settings && this.state.settings.feed_rate !== prevState.settings.feed_rate) {
            // feed_rate was changed
            this.state.settings.feed_rate_changed = true;
        }
        if(this.state && this.state.settings && prevState && prevState.settings && this.state.settings.feed_volume !== prevState.settings.feed_volume) {
            // feed_volume was changed
            this.state.settings.feed_volume_changed = true;
        }
        if(this.state && this.state.settings && prevState && prevState.settings && this.state.settings.feed_interval !== prevState.settings.feed_interval) {
            // feed_interval was changed
            this.state.settings.feed_interval_changed = true;
        }
    }

    /**
     * Should the next button be active
     * @param progress
     * @return {*}
     */
    canGoNext(progress){

        if(this.activeSlide(progress)['preBuilt']) {
            if(this.prebuiltRef.current)
                return this.prebuiltRef.current.canGoNext();
            else
                return false;
        }else{
            const hotspots   = this.canGoNextForHotspots(progress);
            const actions    = this.canGoNextForActions(progress);
            const timer      = this.canGoNextForTimer();

            return hotspots && actions && timer;
        }
    }

    canGoNextForTimer(){
        return this.state.nextTimer === 0
    }

    canGoNextForActions(progress){
        if(!this.activeSlide(progress)['actions'])
            return true;

        return this.activeSlide(progress)['actions'].reduce((accu, current, index) => {
            let isAction = !current['no_action'];
            if (isAction){
                return accu && progress.isActionPerformed(this.state.section.parent, this.sectionID, this.state.activeSlide, index);
            }else{
                return accu;
            }
        },true);
    }

    canGoNextForHotspots(progress){
        if(!this.activeSlide(progress)['hotspots']){
            return true;
        }
        return this.activeSlide(progress)['hotspots'].reduce((acu,current, index) => {
            return acu && progress.isHotspotOpened(this.state.section.parent, this.sectionID,this.state.activeSlide, index);
        },true);
    }

    getActiveSlideHotspots(progress){
        return this.activeSlide(progress)['hotspots'] ? this.activeSlide(progress)['hotspots'] : [];
    }

    isLastScreen(progress){
        return this.state.activeSlide === this.getSlides(progress).length;
    }

    /**
     * Callback from the abstract superclass
     * @param progress
     * @param sections
     * @param sectionsLoading
     * @param lang
     * @returns {JSX.Element}
     */
    renderWithProgress(progress, sections, sectionsLoading, lang) {
        if(this.state.section == null)
            return <Loading/>

        let mainContent;
        let activeSlide = this.activeSlide(progress);
        // Show the "section finished" page as last slide
        if(this.isLastScreen(progress)){
            let next = this.getNext(sections);

            let path = next.id ? SectionScreen.getPathForSection(next.id) : next.path;
            let name = next.name.title ? next.name.title : next.name

            mainContent = (
              <SectionEndScreen title={this.state.section.title} nextName={name} onNext={()=>{
                  /* istanbul ignore next */
                  if(next.path)
                      /* istanbul ignore next */
                      this.setCurrentPage(progress,"TUTORIAL");
                  /* istanbul ignore next */
                  this.props.history.push(path);
                  /* istanbul ignore next */
                  progress.setLastSlide(false);
              }} onRestart={()=>{
                  /* istanbul ignore next */
                  this.setState({activeSlide:0});
                  /* istanbul ignore next */
                  progress.setLastSlide(false);
              }}/>
            );
        }else{
            let title = this.getTitle(progress, lang);
            mainContent = (
                <React.Fragment>
                    <div className={`progress-indicator clearfix ${this.state.section['extra-class'] ? this.state.section['extra-class'] : ""} ${lang.lang} ${activeSlide['extra-class'] ? activeSlide['extra-class'] : ""}`}>
                        <h3>{lang.t(this.state.section.title)}</h3>
                        <div className={`body-copy ${activeSlide["preBuilt"] ? "cassette-selection" : ""}`}>
                            {title}
                            {this.getBody(progress,lang)}
                            {this.getInstructions(lang, progress)}
                            {this.getMainSlideBodyCopy(progress)}
                        </div>
                        {this.getActions(progress, lang)}
                        {this.getMainSlideContent(progress, lang)}
                    </div>
                    {this.getPopup(progress)}

                    {this.getDots(progress)}
                    <div className="parts-slide-buttons">
                        {this.getPrevButton(progress)}
                        {this.getNextButton(progress)}
                    </div>
                    {this.getActionSVGs(progress)}
                    {this.getExtraSVG(progress)}
                </React.Fragment>
            );
        }

        return (
            <div>
                {mainContent}
                {this.state.showTutorial ? (<TutorialOverlay title={this.state.guide.title} slides={this.state.guide.slides} onClose={this.closeTutorial}/>) : ""}
            </div>
        );
    }

    getTitle(progress, lang){
        let title = this.activeSlide(progress).title
        if(title){
            if(typeof title !== "string"){
                title = title.join("<br/>")
            }

            return (<h6 dangerouslySetInnerHTML={{__html: lang.t(title)}}/>)
        }else{
            return ""
        }
    }

    getDots(progress){
        let dotsContent = []

        if(this.state.section['custom_dots']){
            let slide = this.activeSlide(progress)
            for(let i =0 ; i<this.state.section['custom_dots'];i++){
                dotsContent.push(
                    <span className={`progress-dot ${i < slide.dot ? "active" : ""}`} key={`dot-${i}`}/>
                )
            }
        }else{
            dotsContent = this.getSlides(progress).map((slide, i) => (
                <span className={`progress-dot ${i <= this.state.activeSlide ? "active" : ""}`} key={`dot-${i}`}/>
            ))
        }

        return (
            <div className="progress-bar">
                {dotsContent}
            </div>
        )
    }

    /**
     * PARTIAL RENDERINGS
     */

    getBody(progress, lang){
        let body = this.activeSlide(progress).body;
        return this.getMulitPCassetteBasedInfo(progress,lang,body);
    }

    getMainSlideBodyCopy(progress){
        return this.activeSlide(progress)["preBuilt"] ? PreBuiltHelper.getPreBuilt(this.activeSlide(progress)["preBuilt"],this.prebuiltRef, ()=>{this.setState(prev=>({activeSlide: prev.activeSlide}))}) : "";
    }

    /**
     * The main content of a slide is either constructed by interpreting the JSON or by loading a prebuilt page
     * @return {JSX.Element}
     */
    getMainSlideContent(progress, lang){
        return this.activeSlide(progress)["preBuilt"] ? "" : this.getJSONContentBuild(progress, lang);
    }

    /**
     * The main content of a slide is either constructed by interpreting the JSON or by loading a prebuilt page
     * @return {JSX.Element}
     */
    getJSONContentBuild(progress, lang){
        let extra = this.getExtra(progress);
        return (
            <React.Fragment>
                {extra}
                <div className={`omnipump ${extra === "" ? 'omnipump-parts' : "omnipump-instruction"}`}>

                    <div className="omnipump-machine">
                        <img src={this.getMachineImage(progress)} key={this.getMachineImage(progress)} alt="machine preview"/>
                    </div>
                    { this.isMachineImage(progress) && (
                        <React.Fragment>
                            {this.getActionHandles(progress)}
                            {this.getExtraHandle(progress)}
                            {this.getScreenImage(progress, lang)}
                            {this.getLedImage(progress)}
                            {this.getOnScreenNumber(progress)}
                            {this.getChargingLED(progress)}
                            {this.getCassetteImage(progress)}
                        </React.Fragment>
                    )}
                    {this.getActiveSlideHotspots(progress).map((hotspot,i) => (
                        <div
                            onClick={()=> {
                                this.setState({activePopup:i});
                                progress.setHotspotOpened(this.state.section.parent, this.sectionID, this.state.activeSlide, i);

                                if(hotspot['group']){
                                    let hotspots = this.getActiveSlideHotspots(progress);

                                    for(let j =0;j<hotspots.length; j++){
                                        if(hotspots[j]['group'] === hotspot['group']){
                                            progress.setHotspotOpened(this.state.section.parent, this.sectionID, this.state.activeSlide, j);
                                        }
                                    }
                                }
                            }}

                            className={`hotspots ${progress.isHotspotOpened(this.state.section.parent, this.sectionID,this.state.activeSlide, i) ? '' : "pulse"} ${hotspot.position}`}
                            key={`hotspot-${i}`}>
                            <span className={`hotspot ${progress.isHotspotOpened(this.state.section.parent, this.sectionID,this.state.activeSlide, i) ? 'inactive' : ''}`}/>
                        </div>
                    ))}

                </div>
            </React.Fragment>
        );
    }

    getCassetteImage(progress){

        let showCassette = this.state.section['show_cassette'];
        let image = null;

        if(showCassette === true || (typeof showCassette === 'number' && this.state.activeSlide > showCassette)){
            image = CassetteImageHelper.image(progress.cassette);
        }else if(typeof showCassette === "string"){
            image = CassetteImageHelper.image(showCassette)
        }

        if(image){
            return (
                <div className="omnipump-cassette">
                    <img src={image} alt="cassette"/>
                </div>
            )
        }else{
            return "";
        }
    }

    getActionHandles(progress){
        let slide = this.activeSlide(progress)

        if(slide['actions']){
            let actions = slide['actions']
            return (
                <div className="handles">
                    {actions.map((action, i) => this.getActionHandle(action, i, progress))}
                </div>
            );
        }
    }

    getActions(progress, lang){
        let slide = this.activeSlide(progress);

        if(slide['actions'] || slide['end_instruction']){
            let actions = slide['actions'] ?? [];

            let actionsHTML = actions.map((action, i)=>this.getAction(action,i,progress, lang))

            return (
                <div className={`actions ${actions.length > 1 ? "two-actions" : ""}`}>
                    {actionsHTML}
                    {this.getEndInstruction(lang,progress)}
                </div>
            );
        }

        return "";
    }

    getExtraHandle(progress){
        const extraData = this.getExtraData(progress)
        if(extraData){
            return <div className={`handle extra-instruction ${extraData['position'] ? extraData['position'] : ''}`} ref={ref=>this.setExtraRef(ref,"handle")}/>
        }else{
            return ""
        }
    }

    getActionHandle(action, i, progress){
        let isClicked = progress.isActionPerformed(this.state.section.parent, this.sectionID,this.state.activeSlide,i);
        let inner = action['no_action'] ? "" : (<span className={`hotspot ${isClicked ? "inactive" : ""}`} />)

        let secondary = action['secondary'] ? (
            <div
                className={`handle ${action['secondary']} no_action`}
                /* istanbul ignore next */
                onTouchStart={() => this.handleActionMouseDown(action, progress, i, true)}
                /* istanbul ignore next */
                onTouchEnd={() => this.handleActionMouseUp(action, progress, i, true)}
                /* istanbul ignore next */
                onTouchCancel={() => this.handleActionMouseUp(action, progress, i,true)}
                /* istanbul ignore next */
                onClick={() => this.handleAction(action,progress, i, true)}
                /* istanbul ignore next */
                onMouseDown={() => this.handleActionMouseDown(action, progress, i, true)}
                /* istanbul ignore next */
                onMouseUp={() => this.handleActionMouseUp(action, progress, i, true)}/>
        ) : null;

        return (
            <React.Fragment>
              <div key={`action-handle-${i}`}
                   className={`handle ${action['position']} ${action['no_action'] ? "no_action" : ""} ${isClicked ? "clicked" : ""} ${!action['no_action'] && !isClicked ? 'pulse' : ""}`}
                  /* istanbul ignore next */
                   ref={ref=>this.setActionHandleRef(ref,i)}
                  /* istanbul ignore next */
                   onTouchStart={() => this.handleActionMouseDown(action, progress, i)}
                  /* istanbul ignore next */
                   onTouchEnd={() => this.handleActionMouseUp(action, progress, i)}
                  /* istanbul ignore next */
                   onTouchCancel={() => this.handleActionMouseUp(action, progress, i)}
                  /* istanbul ignore next */
                   onMouseDown={() => this.handleActionMouseDown(action, progress, i)}
                  /* istanbul ignore next */
                   onMouseUp={() => this.handleActionMouseUp(action, progress, i)}
                  /* istanbul ignore next */
                   onClick={()=>{this.handleAction(action,progress, i)}}>
                  {inner}
              </div>
                {secondary}
            </React.Fragment>
        );
    }

    getAction(action, i, progress, lang){
        let instruction = this.getMulitPCassetteBasedInfo(progress,lang,action['instruction'],"instruction");
        let comment = this.getMulitPCassetteBasedInfo(progress,lang,action['comment'],"comment");
        let disclaimer = action['disclaimer'] ? <div className={"disclaimer"}>{lang.t(action['disclaimer'])}</div> : '';

        return (
            <>
                <div className={`action ${action['position']} ${action['no_action'] ? "no_action" : ""}`} key={`action-ind-${i}`}>
                    <div className="action-inner" ref={ref=>this.setActionRef(ref,i)}>
                        {comment}
                        {instruction}
                    </div>
                </div>
                {disclaimer}
            </>
        )
    }

    getExtraSVG(progress){
        let data = this.getExtraData(progress)

        if(data){
            let refs = this.state.extraRef;
            if(refs.box && refs.handle){

                return <ActionLine start={offsetSVGParent(refs.box.getBoundingClientRect())}
                                   end={offsetSVGParent(refs.handle.getBoundingClientRect())}
                                   isMobile={this.state.windowWidth < 768}
                                   aroundMobile={data.aroundMobile}/>
            }
        }else{
            return "";
        }
    }

    getActionSVGs(progress){
        let slide = this.activeSlide(progress);

        if(slide['actions']) {
            let actions = slide['actions'];

            return (
                <React.Fragment>
                    {actions.map((action,i) => {
                        let refs = this.state.actionRefs

                        if(refs[i] && refs[i].box && refs[i].handle) {
                            return <ActionLine start={offsetSVGParent(refs[i].box.parentElement.getBoundingClientRect())}
                                               end={offsetSVGParent(refs[i].handle.getBoundingClientRect())}
                                               isMobile={this.state.windowWidth < 768}
                                               extraClass={action['no_action'] ? "no-action":null}
                                               key={`svg-${i}`}/>
                        }
                    })}
                </React.Fragment>
            );
        }else{
            return "";
        }
    }

    setExtraRef(ref, key){
        let oldRef = this.state.extraRef;

        if(!oldRef[key]) {
            oldRef[key] = ref;
            this.setState({extraRef:oldRef})
        }
    }

    setActionRef(ref, i){
        let refs = this.state.actionRefs

        if(!refs[i]){
            refs[i] = {}
        }

        if(!refs[i].box){
            refs[i].box = ref
            this.setState({actionRefs:refs})
        }
    }

    setActionHandleRef(ref,i){
        let refs = this.state.actionRefs

        if(!refs[i]){
            refs[i] = {}
        }

        if(!refs[i].handle) {
            refs[i].handle = ref
            this.setState({actionRefs:refs})
        }
    }

    getCassetteBasedInfo(progress, info){
        if(typeof info === "string")
            return info;

        if(progress.cassette)
            return info[progress.cassette];

        return info[Object.keys(info)[0]];
    }

    getInstructions(lang, progress, key = 'instruction'){
        const r = this.activeSlide(progress)
        let info = r[key];
        if(!info)
            return <></>;

        let text = this.getCassetteBasedInfo(progress, info);
        return <div className="instructions" key={this.state.activeSlide}><p dangerouslySetInnerHTML={{__html: lang.t(text)}}></p></div>;
    }

    getEndInstruction(lang, progress){
        return this.getInstructions(lang,progress,'end_instruction')
    }

    getOnScreenNumber(progress){
        let slide = this.activeSlide(progress);
        checkFeedRules(slide,this.state);
        return generateOnScreenNumber(slide, this.state);
    }

    getScreenImage(progress, lang){
        return !this.isBackImage(progress) ? (<div className="omnipump-screen" key={JSON.stringify(progress)}><img src={this.getScreen(progress, lang)} alt="screen"/></div>) : "";
    }

    getLed(progress){
        let parentLed = this.state.section['led'];
        let led = this.activeSlide(progress)['led'];
        let finalLed = 'all';
        if(led){
            finalLed = led
        }else if(parentLed){
            finalLed = parentLed
        }
        return  LedImagesHelper.image(finalLed);
    }

    getLedImage(progress){
        return !this.isBackImage(progress) ? <div className="omnipump-led"><img src={this.getLed(progress)} alt="leds"/></div> : ""
    }

    getChargingLED(progress){
        let parentChargingLed = this.state.section["charging-off"];
        let chargingLed = this.activeSlide(progress)['charging-off'];

        const condition = (parentChargingLed === true && chargingLed !== false) || (!parentChargingLed && chargingLed)
        return condition ? <div className="omnipump-charging-led"><img src={LedImagesHelper.image("one-off")} alt="charging led off"/></div> : "";
    }

    getPrevButton(progress){
        return this.state.activeSlide > 0 ? <Button next={false} onClick={()=>{this.goPrev(progress)}}/> : "";
    }

    getNextButton(progress){
        return this.state.activeSlide < this.getSlides(progress).length ? <Button active={this.canGoNext(progress)} next={true} onClick={()=>{this.goNext(progress)}}/> : "";
    }

    goNext(progress){
        if(this.state.activeSlide === this.getSlides(progress).length - 1) {
            progress.setSubsectionCompleted(this.state.section.parent, this.sectionID);
            progress.setLastSlide(true);
        }
        this.setState(prev => ({
            nextTimer: 0,
            activeSlide:Number(prev.activeSlide)+1,
            actionRefs:{},
            extraRef:{}}
        ), () => this.handleNextSlide(progress));
    }

    goPrev(progress){
        if(this.timeoutID !== -1)
            clearTimeout(this.timeoutID)
        progress.setLastSlide(false);
        this.setState(prev => ({activeSlide:prev.activeSlide-1,actionRefs:{}, extraRef:{}}),() => this.handleNextSlide(progress))
    }

    handleNextSlide(progress){
        this.preloadNextSectionImages(progress)
        let slide = this.activeSlide(progress)
        if(slide) {
            if(slide['timer']){
                this.setState(prev => ({
                    nextTimer: 1
                }))
                this.timeoutID = setTimeout(() => {
                    this.setState(prev => ({
                        nextTimer: 0
                    }))
                    this.timeoutID = -1
                },slide['timer']*1000)
            }else{
                this.setState(prev => ({
                    nextTimer: 0
                }))
            }

            let aud = slide['audio']

            if (aud) {
                this.audio = new Audio(AudioHelper.getFile(aud))
                this.audio.play();
            } else {
                this.destroyAudio();
            }
        }else{
            this.destroyAudio()
        }
    }

    destroyAudio(){
        if(this.audio){
            this.audio.pause();
        }
        this.audio = null;
    }

    getExtra(progress){
        return this.getExtraData(progress) ? <ExtraInstructions setRef={ref=>this.setExtraRef(ref,"box")} info={this.activeSlide(progress)['extra_instructions']}/> : "";
    }

    getExtraData(progress){
        return this.activeSlide(progress)['extra_instructions'];
    }

    getPopup(progress){
        let activeHotspot = this.activeHotspot(progress);
        return activeHotspot !== null ? <HotspotModal onClose={this.closePopup} hotspot={activeHotspot}/> : "";
    }

    /**
     * END PARTIAL RENDERS
     */

    timeoutAction(progress, action, actionIndex,){
        progress.setActionPerformed(this.state.section.parent, this.sectionID, this.state.activeSlide, actionIndex);

        if (action['timeout.screenTo']) {
            let slide = this.activeSlide(progress)
            this.handleScreenToAction({screenTo:action['timeout.screenTo']}, progress, slide);
            this.updateSlide(slide,progress)
            this.setState({actionTimeoutID: null})
        }
    }

    /*** ACTION HANDLERS ****/
    handleActionMouseDown(action, progress, actionIndex, secondary = false){
        if(action['timeout.time']){
            const instance = this
            let id = setTimeout((() => {
                instance.timeoutAction(progress, action, actionIndex)
            }), action['timeout.time'])

            this.setState({actionTimeoutID: id})
        }else {
            if(this.state.section)
                progress.setActionPerformed(this.state.section.parent, this.sectionID, this.state.activeSlide, actionIndex);
        }

        let slide = this.activeSlide(progress)

        if(!secondary){
            if(action['mousedown.screenTo']){
                this.handleScreenToAction({screenTo: action['mousedown.screenTo']}, progress, slide);
            }
        }
    }

    handleActionMouseUp(action, progress, actionIndex, secondary = false){
        if(action["timeout.time"]){
            if(this.state.actionTimeoutID){
                clearTimeout(this.state.actionTimeoutID)
            }else{
                return;
            }
        }else {
            progress.setActionPerformed(this.state.section.parent, this.sectionID, this.state.activeSlide, actionIndex);
        }

        let slide = this.activeSlide(progress)

        if(!secondary){
            if(action['mouseup.screenTo']){
                this.handleScreenToAction({screenTo: action['mouseup.screenTo']}, progress, slide);
            }
        }
    }

    handleAction(action, progress, actionIndex, secondary = false){
        if(action["timeout.time"] === undefined) {
            progress.setActionPerformed(this.state.section.parent, this.sectionID, this.state.activeSlide, actionIndex);
        }
        let slide = this.activeSlide(progress)

        if(!secondary) {
            if (action['screenTo']) {
                this.handleScreenToAction(action, progress, slide);
            }

            if (action['ledTo']) {
                this.handleLedToAction(action, progress, slide);
            }

            if(action['onscreenNumberTo']){
                this.handleOnScreenNumberToAction(action, progress, slide);
            }
        }

        if(action['change_settings']){
            this.setState(prev => ({
                settings:{
                    ...prev.settings,
                    [action['change_settings']]:prev.settings[action['change_settings']]+(
                        ((action['limit_min'] || action['limit_min'] === 0) && action['limit_min'] === prev.settings[action['change_settings']] && secondary) ? 0 :
                        ((action['limit_max']  || action['limit_max'] === 0) && action['limit_max'] === prev.settings[action['change_settings']] && !secondary) ? 0 :
                        (action['limit'] && action['limit'] === prev.settings[action['change_settings']]) ? 0 : (secondary ? -1 : 1)
                    )
                }
            }))
        }

        this.updateSlide(slide,progress)

        //if autoNext then just go next
        if(action['autoNext'] && action['autoNext'] === true) {
            this.goNext(progress);
        }
    }

    changeSetting(newSetting) {
        this.setState({setting: newSetting});
    }

    handleOnScreenNumberToAction(action, progress, slide){
        if(action['onscreenNumberTo'] === -1){
            slide['onscreenNumber'] = undefined
        }else {
            slide['onscreenNumber'] = action['onscreenNumberTo'];
        }
    }

    /**
     * Change the screen to the one marked by the action
     * @param action
     * @param progress
     * @param slide
     */
    handleLedToAction(action, progress,slide){
        slide['led'] = this.getCassetteBasedInfo(progress,action['ledTo']);
    }

    /**
     * Change the screen to the one marked by the action
     * @param action
     * @param progress
     * @param slide
     */
    handleScreenToAction(action, progress,slide){
        let section = this.state.section; 
        let cassette = false;
        if(progress && progress.cassette) {
            cassette = progress.cassette;
        }
        if(action['screenTo_thick'] && section && cassette && section['thick'] && section['thick'][cassette]) {
            slide['screen_thick'] = this.getCassetteBasedInfo(progress,action['screenTo_thick']);
        } else {
            slide['screen'] = this.getCassetteBasedInfo(progress,action['screenTo']);
        }
    }

    updateSlide(slide, progress){
        let section = this.state.section;

        let slides = this.getSlides(progress);
        slides[this.state.activeSlide] = slide;

        if(Array.isArray(this.state.section.slides)){
            section.slides = slides;
        }else{
            let cassette = false;
            if(progress && progress.cassette) {
                cassette = progress.cassette;
            }
            let slidesKey;
            if(cassette)
                slidesKey = this.state.section.slides[cassette];
            else
                slidesKey = this.state.section.slides[Object.keys(this.state.section.slides)[0]];

            if(Array.isArray(slidesKey)){
                section.slides[cassette] = slides;
            }else{
                section["slides_ref"][slidesKey] = slides;
            }
        }

        this.setState({section})
    }

    /*** END ACTION HANDLERS ****/

    getScreen(progress, lang){
        let section = this.state.section; 
        let cassette = false;
        if(progress && progress.cassette) {
            cassette = progress.cassette;
        }
        let screen = '';
        if(this.activeSlide(progress)['screen_thick'] && section && cassette && section['thick'] && section['thick'][cassette]) {
            screen = this.activeSlide(progress)['screen_thick'];
        } else {
            screen = this.activeSlide(progress)['screen'];
        }
        let image = "";
        if(!screen || typeof screen === "string")
            image = screen
        else {
            if(screen[cassette])
                image = screen[cassette]
            else {
                let key = Object.keys(screen)[0]
                image = screen[key];
            }
        }

        return ScreenImageHelper.getScreen(image, lang);
    }

    setNotFirstRun(){
        cookies.set(CONSTANTS.first_run_key,false);
    }

    /**
     * Close the guid overlay without going to the first section of the experience
     */
    closeTutorial(){
        this.setState({showTutorial:false})
        this.setNotFirstRun();
    }

    /**
     * Get the id and the name of the next section so a button can be rendered to go to that section
     * @param sections
     */
    getNext(sections){
        if(!this.state.section) return {name: ""};
        let parent = this.state.section.parent;
        let parentSubsections = sections[parent]['subsections'];

        let subsectionIDs = Object.keys(parentSubsections);
        let currentIndex = subsectionIDs.indexOf(this.sectionID);

        /**
         * If this is the last of the subsections, we need to go to the next section
         * else, show the next subsection
         */
        if(currentIndex === subsectionIDs.length - 1){

            let sectionIDs = Object.keys(sections);
            let ci = sectionIDs.indexOf(parent);
            let nextIndex = ci + 1;

            let nextSection = sections[sectionIDs[nextIndex]];
            if(nextSection) {
                let nextSubsections = nextSection['subsections'];

                if (nextSubsections) {
                    let nextSubsectionIDs = Object.keys(nextSubsections);
                    let nextSubsectionID = nextSubsectionIDs[0];

                    return {
                        id: nextSubsectionID,
                        name: nextSubsections[nextSubsectionID]
                    }
                }
            }else{
                return {
                    path: TestScene.getPath(),
                    name: "Test Your Knowledge"
                }
            }

        }else{
            let nextIndex = currentIndex + 1;

            return {
                id: subsectionIDs[nextIndex],
                name: parentSubsections[subsectionIDs[nextIndex]]
            }
        }
    }

    /**
     * Support values to be cassette dependend but also multi paragraph.
     * This means that a value can be:
     *
     * 1. key: value
     * 2. key: [paragraph_1, paragraph_2,...],
     * 3. key: {cassette1:value, cassette2:value},
     * 4. key: {cassette1: [paragraph1,paragraph2..]}
     * @param progress
     * @param lang
     * @param data
     * @param clazz
     * @return {JSX.Element|string|*|string}
     */
    getMulitPCassetteBasedInfo(progress,lang, data, clazz = null){
        if(!data)
            return "";

        if(typeof data === "string"){
            return <p key={data} className={clazz} dangerouslySetInnerHTML={{__html:lang.t(data)}}/>;
        }else if(Array.isArray(data)){
            return <React.Fragment>{data.map((d,i) => <p className={clazz} key={`s1-${i}`} dangerouslySetInnerHTML={{__html:lang.t(d)}}/>)}</React.Fragment>;
        }else{
            let cdata = this.getCassetteBasedInfo(progress,data);
            return this.getMulitPCassetteBasedInfo(progress,lang,cdata,clazz);
        }
    }

    preloadImage(url){
        new Promise((res, err) => {
            const img = new Image() ;
            img.src = url;
            img.onload = res;
            img. onerror = err;
        }).then().catch(error=>{});
    }

    preloadNextSectionImages(progress){
        let slides = this.getSlides(progress);
        let nextIndex = this.state.activeSlide + 1;
        let section = this.state.section; 
        let cassette = false;
        if(progress && progress.cassette) {
            cassette = progress.cassette;
        }
        
        if(slides[nextIndex]){
            let nextSlide = slides[nextIndex]

            if(nextSlide['screen_thick'] && section && cassette && section['thick'] && section['thick'][cassette]) {
                this.preloadImage(nextSlide['screen_thick'])
            } else if(nextSlide['screen']){
                this.preloadImage(nextSlide['screen'])
            }
            if(nextSlide['actions']){
                nextSlide['actions'].forEach(el => {
                    if(el['screenTo']){
                        this.preloadImage(el['screenTo'])
                    }
                })
            }
        }
    }

    /* PATHS */

    static getPathForSection(sectionID){
        return SectionScreen.getPath()+sectionID;
    }

    static getPath(){
        return "/section/"
    }

    getPath(){
        return SectionScreen.getPathForSection(this.sectionID);
    }
}
