import React from "react";
import SectionScreen from "./main_pages/SectionScreen";
import LiMenuLink from "./LiMenuLink";
import AbstractProgressSupportingComponent from "./abstract_components/AbstractProgressSupportingComponent";
import LiMainMenuLink from "./LiMainMenuLink";

export default class LeftMenuSection extends AbstractProgressSupportingComponent{

    verifyDependencies(progress, lang, key){
        let dep = this.props.section['dependencies'];

        if(!dep)
            return null; // if there are no dependencies, we accept all sections

        let deps = dep[key];
        if(deps){
            let met = deps.reduce((acc, current) => {
                return acc && progress.isSubSectionCompleted(this.props.sectionID, current);
            }, true);

            /* istanbul ignore next */
            if(met){
                return null;
            }else{
                /* istanbul ignore next */
                if(deps.length > 1) {
                    let names = deps.map((val) => this.props.section['subsections'][val]);
                    names = names.map(val => lang.t(val)).join(", ");

                    return lang.t("To continue learning, please review all the information in the following sections:")+" "+names;
                }else{
                    return lang.t("To continue learning, please review all the information in the")+" "+lang.t(this.props.section['subsections'][deps[0]])+" "+lang.t("section");
                }
            }
            /* istanbul ignore next */
        }else{
            return null;
        }
    }

    /* istanbul ignore next */
    renderWithProgress(progressContext, sections, sectionsLoading, lang) {
        let subsections;

        if(this.props.section['subsections'] !== undefined){
            subsections = (
                <div className={`nav__listitem__slides ${this.props.active ? "active" : ""}`}>
                    <ul>
                        {Object.keys(this.props.section['subsections']).map((key, i) => {
                            let depIssue = this.verifyDependencies(progressContext,lang,key)
                            return (
                                <LiMenuLink
                                    lang={lang}
                                    to={depIssue ? null : SectionScreen.getPathForSection(key)}
                                    label={this.props.section['subsections'][key]}
                                    key={key}
                                    sectionID={this.props.sectionID}
                                    progressContext={progressContext}
                                    isLearnt={progressContext.isSubSectionCompleted(this.props.sectionID, key)}
                                    onClick={(e, isActive)=>{
                                        if(!isActive)
                                            progressContext.setLastSlide(false)
                                        if(depIssue)
                                            progressContext.setDependencyIssue(depIssue)
                                        this.setCurrentPage(progressContext,this.props.sectionID);
                                    }}
                                />
                            )
                        })}
                    </ul>
                </div>
            );
        }else{
            subsections = "";
        }

        let isLearnt = Object.keys(this.props.section['subsections']).reduce((acc, curr)=>{
            return progressContext.isSubSectionCompleted(this.props.sectionID,curr) && acc;
        },true);

        return (
            <React.Fragment>
                <LiMainMenuLink active={this.props.active} label={lang.t(this.props.section.title)} to={null} isLearnt={isLearnt} onClick={this.props.onClick}/>
                {subsections}
            </React.Fragment>
        );
    }
}
