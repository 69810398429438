import AbstractLanguageSupportingComponent from "./abstract_components/AbstractLanguageSupportingComponent";
import Button from "./Button";

export default class SectionEndScreen extends AbstractLanguageSupportingComponent{

    renderWithLanguage(lang) {
        return (
            <div className="progress-indicator__img">
                <div className="kangaroo-background">
                    <img src="/images/sep.png" alt="background"/>
                </div>
                <div className="progress-indicator__img-desc">
                    <div className="checkmark">
                        <img src="/images/checkmark.png" alt="checkmark"/>
                    </div>
                    <h4>{lang.t(this.props.title)}</h4>
                    <Button next={true} white={true} text={lang.t(this.props.nextName)} onClick={this.props.onNext} rightArrow="/images/red-right-arrow.png"/>
                    <Button next={false} transparent={true} text={`${lang.t(`Restart`)} ${lang.t(this.props.title)} ${lang.t(`section`)}`} onClick={this.props.onRestart}/>
                </div>
            </div>
        )
    }
}
