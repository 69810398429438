import React from "react";
import LeftMenu from "./components/LeftMenu"
import InitialLoading from './components/InitialLoading'
import {LanguageProvider, LanguageContext} from "./components/context_providers/LanguageContext"
import WelcomeScreen from "./components/main_pages/WelcomeScreen";
import {BrowserRouter as Router, Switch, Route, HashRouter} from "react-router-dom";
import TestScene from "./components/main_pages/TestScene";
import {CookiesProvider} from "react-cookie";
import SectionScreen from "./components/main_pages/SectionScreen";
import {SectionProvider} from "./components/context_providers/SectionsContext";
import {ProgressContext, ProgressProvider} from "./components/context_providers/ProgressContext";
import SectionScreenWrapper from "./components/main_pages/SectionScreenWrapper";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.handleMenu = this.handleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);

        this.state = {menuOpen: props['menuOpen'] ? props['menuOpen'] : false}
        this.menuRef = React.createRef();
    }

    handleMenu() {
        this.setState(prev => ({menuOpen: !prev.menuOpen}))
    }

    closeMenu(e) {
        if (this.state.menuOpen) {
            e.stopPropagation();
            if(this.menuRef && this.menuRef.current)
                this.menuRef.current.toggle();
        }
    }

    /* istanbul ignore next */
    render() {
        return (
            <div id="app-holder">
                <HashRouter>
                    <CookiesProvider>
                        <LanguageProvider>
                            <SectionProvider>
                                <ProgressProvider>
                                    <LanguageContext.Consumer>
                                        {context => {
                                            /* istanbul ignore next */
                                            if (context.initialized)
                                                return this.renderMainContent();
                                            else
                                                return (<InitialLoading/>);
                                        }}
                                    </LanguageContext.Consumer>
                                </ProgressProvider>
                            </SectionProvider>
                        </LanguageProvider>
                    </CookiesProvider>
                </HashRouter>
            </div>
        );
    }

    /* istanbul ignore next */
    renderMainContent(){
        return <ProgressContext.Consumer>
            {progress => this.renderMainContentCore(progress)}
        </ProgressContext.Consumer>
    }

    renderMainContentCore(progress){
        return (
            <div
                className={`${this.state.menuOpen ? "navigation-open" : "navigation-closed"}`}>
                <LeftMenu ref={this.menuRef} handler={this.handleMenu}/>
                <div id="svg-root"
                     className={`main ${this.state.menuOpen ? "main-covered" : ""} ${progress.isLastSlide ? "last-slide" : ""}`}
                     onClick={this.closeMenu}>
                    <Switch>
                        <Route exact path={TestScene.getPath()}
                               component={TestScene}/>
                        <Route exact
                               path={SectionScreen.getPathForSection(":sectionID")}
                               component={SectionScreenWrapper}/>
                        <Route path={WelcomeScreen.getPath()}
                               component={WelcomeScreen}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

export default App;
