import React from "react";
import AbstractLanguageSupportingComponent from "./abstract_components/AbstractLanguageSupportingComponent";

export default class HotspotModal extends AbstractLanguageSupportingComponent{

    constructor(props) {
        super(props);

        let hotspot = props.hotspot;

        this.title = hotspot['popup_headline'];
        this.body = hotspot['popup_content'];
        this.position = hotspot['position'];
    }

    /**
     *
     * @param lang
     * @return {JSX.Element}
     */
    renderWithLanguage(lang) {
        let columns = this.renderColumns(lang);
        return (
            <div className={`modal ${columns === "" ? "" : "columns"}`}>
                <div className="modal__content__wrap">
                    <div className="modal__content__container">
                        <div className={`modal__content ${this.position}`}>
                            <span className="close" onClick={this.props.onClose}>X</span>
                            <h5 dangerouslySetInnerHTML={{__html:lang.t(this.title)}}/>
                            <p className="modal__content__body" dangerouslySetInnerHTML={{__html:lang.t(this.body)}}/>
                            {columns}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderColumns(lang){
        let columns = this.props.hotspot['popup_columns'];
        if(columns){
            let title = this.props.hotspot['popup_columns_title'];

            return (
              <React.Fragment>
                  <p className="definitions">{lang.t(title)}</p>
                  <div className="icons clearfix">
                      {columns.map((column,i) => (
                          <div className="icons-column" key={`icon-column-${i}`}>
                              <img src={column.image} alt="icon"/>
                              <p className="icons-headline">{lang.t(column.headline)}</p>
                              <p className="icons-copy" dangerouslySetInnerHTML={{__html:lang.t(column.body)}}/>
                          </div>
                      ))}
                  </div>
              </React.Fragment>
            );
        }else{
            return "";
        }
    }
}