export const ASSETS_BASE_URL = "";
export const BASE_URL = `${ASSETS_BASE_URL}/data`;

const CONSTANTS = {
    welcome_url:`${BASE_URL}/welcome-screen.json`,
    languages_url:`${BASE_URL}/languages.json`,
    sections_url:`${BASE_URL}/sections.json`,
    guide_url:`${BASE_URL}/guide.json`,
    test_url: `${BASE_URL}/test.json`,
    cassette_url: `${BASE_URL}/cassette.json`,
    first_run_key:"first_run",
    section_url: id => `${BASE_URL}/sections/${id}.json`,
    image: path => `${ASSETS_BASE_URL}/images/${path}`,
    audio: path => `${ASSETS_BASE_URL}/audio/${path}`
}

export default CONSTANTS;