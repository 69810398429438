import BasePrebuiltPages from "../abstract_components/BasePrebuiltPages";
import CassetteContentProvider from "../../content_providers/CassetteContentProvider";
import Loading from "../Loading";
import React from "react";
import CONSTANTS from "../../helpers/Contstants";
import CassetteImageHelper from "../../helpers/CassetteImageHelper";
import CassetteHelper from "../../helpers/CassetteHelper";
import {EqualHeight, EqualHeightElement} from "react-equal-height";

export default class CassetteSelectionScreen extends BasePrebuiltPages{

    activeCassette = -1;

    constructor(props) {
        super(props);

        this.state = {data:props['data'] ? props['data'] : null, showPopup: props['showPopup'] !== undefined ? props['showPopup'] : true, activeCassette: props['activeCassette'] ? props['activeCassette'] : -1, showCassettePopup: props['showCassettePopup'] ? props['showCassettePopup'] : false};

        this.cassetteList = this.cassetteList.bind(this);
        CassetteContentProvider.getContent().then(json => {
            this.setStateAccordingly("data",json);
        });
    }

    canGoNext() {
        return this.activeCassette !== -1;
    }

    renderWithProgress(progress, sections, sectionsLoading, lang) {
        if(this.state.data === null)
            return <Loading/>

        return (
            <React.Fragment>
                {this.cassetteList(lang, progress)}
                {this.cassettePopup(lang)}
            </React.Fragment>
        );
    }

    cassetteList(lang, progress){
        let cassettes = CassetteHelper.getCassettesForLanguage(this.state.data['cassettes'],lang);

        return (
            <React.Fragment>
                <div className="feeding-sets clearfix">
                    <EqualHeight>
                        {Object.keys(cassettes).map((cassetteID, i)=>(

                                <div
                                    onClick={()=>{
                                        this.activeCassette = cassetteID
                                        this.setState({activeCassette:cassetteID,showCassettePopup:true});
                                        progress.setCassette(cassetteID);
                                    }}
                                    className={`fs-item ${cassetteID == this.state.activeCassette ? "active" : ""}`}
                                    key={`cassette-${i}`}>
                                    <EqualHeightElement name={'fs-item'}>
                                        <p>{lang.t(cassettes[cassetteID].title)}</p>
                                    </EqualHeightElement>
                                    <img src={CassetteImageHelper.image(cassetteID)} alt="cassette"/>
                                </div>

                        ))}
                    </EqualHeight>
                </div>
                <small>{lang.t("Note: Please make sure the feeding set/cassette you want to program the pump with in the next section is selected before you move on.")}</small>
                {this.cassetteDescription(lang)}
                {this.cassetteMobilePopup(lang)}
            </React.Fragment>
        );
    }

    cassetteMobilePopup(lang){
        if(this.state.activeCassette === -1 || !this.state.showCassettePopup){
            return "";
        }else{
            let c = this.state.data['cassettes'][this.state.activeCassette];
            return (
                <div className="feeding-sets-description fsd-mobile" onClick={()=>this.setState({showCassettePopup:false})}>
                    <div className="fs-item-wrap">
                        <div className="fs-item-container">
                            <div className="fs-item-more fs-item1" onClick={(e) => e.stopPropagation()}>
                                <div className="fsp-close">
                                    <p onClick={()=>this.setState({showCassettePopup:false})}>
                                        <strong>X</strong>
                                        {lang.t("Close")}
                                    </p>
                                </div>
                                <p>{lang.t(c['title'])}</p>
                                <img src={CassetteImageHelper.image(this.state.activeCassette)} alt="cassette"/>
                                {this.cassetteCopy(lang,c)}
                            </div>
                        </div>
                    </div>
                    
                </div>
            )
        }
    }

    cassetteDescription(lang){
        if(this.state.activeCassette === -1){
            return "";
        }else{
            let c = this.state.data['cassettes'][this.state.activeCassette];
            return (
                <div className="feeding-sets-description fsd-tab-dsk" style={{display:'block'}}>
                    <div className="fs-item-more fs-item1">
                        {/*<p>{lang.t(c['title'])}</p>*/}
                        {this.cassetteCopy(lang,c)}
                    </div>
                </div>
            );
        }
    }

    cassetteCopy(lang, c){
        let disc = c['disclaimer'] ? <p><span>{lang.t(c['disclaimer'])}</span></p> :"";
        return (
            <div className="fsm-copy">
                <p><strong>{lang.t(c['title'])}:</strong></p>
                <p>{lang.t(c['description'])}</p>
                {disc}
            </div>
        )
    }

    /**
     * Popup to show when screen appears for the first time
     */
    cassettePopup(lang){
        if(!this.state.showPopup)
            return "";

        return (
            <div className="feeding-sets-popup" onClick={()=>this.hidePopup()}>
                <div className="fsp-wrapper" onClick={(e)=>e.stopPropagation()}>
                    <div className="fsp-close" onClick={()=>this.hidePopup()}>
                        <p>X {lang.t('Close')}</p>
                    </div>
                    <div className="fsp-copy">
                        <h3>{lang.t(this.state.data['popup'] ? this.state.data['popup'].title : "")}</h3>
                        <p>{lang.t(this.state.data['popup'] ? this.state.data['popup'].body : "")}</p>
                        <div className="fsp-img-mob">
                            <img src={CONSTANTS.image("fsp-mob.png")} alt="illustration"/>
                        </div>
                        <div className="fsp-img-tab">
                            <img src={CONSTANTS.image("fsp-tab.png")} alt="illustration"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    hidePopup(){
        this.setState({showPopup:false})
    }

}