import IncreasingText from "../components/IncreasingText";
import React from "react";

export function generateOnScreenNumber(data, state, callback){
    if(data['onscreenNumber']){
        let o = data['onscreenNumber'];
        let allOSN = Array.isArray(o) ? o : [o]

        const generateKey = (index, info, value) => {
            return `osn-${index}-${JSON.stringify(info)}-${JSON.stringify(value)}`
        }

        return allOSN.map((osn,index) => (
            <>
                {
                    osn['settingsKey'] ?
                        <IncreasingText onChange={callback} key={generateKey(index, osn, state.settings[osn['settingsKey']])} onscreenNumber={osn} value={state.settings[osn['settingsKey']]} settings={state.settings}/> :
                        <IncreasingText onChange={callback} key={generateKey(index, osn, {})} onscreenNumber={osn} settings={state.settings}/>
                }
            </>
        ))
    }else{
        return [];
    }
}

export function checkFeedRules(data, state) {
    if(data['onscreenNumber']) {
        let min = -1;
        let max = -1;
        if(data['onscreenNumber']['settingsKey'] == 'feed_rate' && state.settings.feed_interval_changed && state.settings.feed_volume_changed && !state.settings.feed_rate_changed) {
            //feed_rate >= feed_volume/feed_interval
            min = Math.round(state.settings.feed_volume / state.settings.feed_interval);
            //if the value is already wrong then we increase it by 5
            if(state.settings.feed_rate < min) {
                state.settings.feed_rate = min + 5;
            }

        } else if (data['onscreenNumber']['settingsKey'] == 'feed_interval' && state.settings.feed_rate_changed && state.settings.feed_volume_changed && !state.settings.feed_interval_changed) {
            //feed_interval >= feed_volume/feed_rate
            min = Math.round(state.settings.feed_volume / state.settings.feed_rate);
            //if the value is already wrong then we increase it by 5
            if(state.settings.feed_interval < min) {
                state.settings.feed_interval = min + 5;
            }

        } else if (data['onscreenNumber']['settingsKey'] == 'feed_volume' && state.settings.feed_interval_changed && state.settings.feed_rate_changed && !state.settings.feed_volume_changed) {
            //feed_volume <= feed_rate*feed_interval
            max = Math.round(state.settings.feed_rate * state.settings.feed_interval);
            //if the value is already wrong then we lower it by 5
            if(state.settings.feed_volume > max) {
                state.settings.feed_volume = max>=5 ? max-5 : 0;
            }
        } else return [];

        if(data['actions']) {
            data['actions'].map(action => {
                if(action['change_settings'] === data['onscreenNumber']['settingsKey']) {
                    if(max !== -1) {
                        action['limit_max'] = max;
                    }else if(min !== -1) {
                        action['limit_min'] = min;
                    }
                }
            });
        }
    } else return [];
}