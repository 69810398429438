import {Link, useHistory, useRouteMatch} from "react-router-dom";
import React from "react";


export default function LiMenuLink({ label, to, isLearnt, lang = null, progressContext = null,  onClick=null, hideCheckmark = false, sectionID = ""}) {
    let match = useRouteMatch({
        path: to,
        exact: true
    });

    const history = useHistory();

    let title = typeof label === "string" ? label : label.title
    if(lang) title = lang.t(title)

    let link = "";
    if(to){
        link = <Link to={to} onClick={(e)=>onClick(e,match)}>{title}</Link>
    }else{
        /* istanbul ignore next */
        link = <a onClick={(e)=>{
            e.preventDefault();
            if(onClick)
                onClick(e, match);
        }} href="/">{title}</a>
    }

    let subItems = ""
    if(typeof label !== "string"){
        /* istanbul ignore next */
        subItems = (
            <ul className="children">
                {label.children.map((c) =>
                    <LiMenuLink key={c.section} label={lang.t(c.title)} hideCheckmark={true} onClick={()=>{
                        if(progressContext && sectionID !== "") {
                            if(to && progressContext.currentPage !== sectionID) {
                                progressContext.setCurrentPage(sectionID)
                                history.push(`${to}?slide=${c.section}`)
                            }else{
                                history.push(`?slide=${c.section}`)
                            }
                        }else{
                            history.push(`?slide=${c.section}`)
                        }
                    }}/>
                )}
            </ul>
        )
    }

    /* istanbul ignore next */
    return (
        <li className={`nav__listitem__slidesitem ${match ? 'active' : ""} ${isLearnt ? 'learnt' : ''}`}>
            { !hideCheckmark && (<span className="checkmark"/>)}
            {link}
            {subItems}
        </li>
    );
}