import React from "react";
import AbstractMainPage from "../abstract_components/AbstractMainPage";
import TestContentProvider from "../../content_providers/TestContentProvider";
import Loading from "../Loading";
import Button from "../Button";
import CONSTANTS from "../../helpers/Contstants";
import ScreenImageHelper from "../../helpers/ScreenImageHelper";
import CassetteContentProvider from "../../content_providers/CassetteContentProvider";
import CassetteHelper from "../../helpers/CassetteHelper";
import CassetteImageHelper from "../../helpers/CassetteImageHelper";
import LedImagesHelper from "../../helpers/LedImagesHelper";
import {EqualHeight, EqualHeightElement} from "react-equal-height";
import {generateOnScreenNumber} from "../../helpers/helpers";

export default class TestScene extends AbstractMainPage{
    static getPath(){
        return "/test-your-knowledge";
    }

    constructor(props) {
        super(props);

        this.goNext = this.goNext.bind(this);
        this.goPrev = this.goPrev.bind(this);
        this.handleClickIncorrect = this.handleClickIncorrect.bind(this);
        this.handleClickCorrect = this.handleClickCorrect.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);

        this.state = {
            activeTask: props['activeTask'] ? props['activeTask'] : -1,
            canGoNext: true,
            correct: props['correct'] ? props['correct'] : false,
            incorrect: props['incorrect'] ? props['incorrect'] : false,
            activeStep: props['startFromStep'] ? props['startFromStep'] : 0,
            cassette: props['cassette'] ? props['cassette'] : null,
            cassettes: props['cassettes'] ? props['cassettes'] : {},
            data: props['data'] ? props['data'] : null,
            tempScreen: props['tempScreen'] ? props['tempScreen'] : null,
            actionTimeoutID: null,
            settings:{
                rate: 120,
                feed_rate: 15,
                pause_duration: 5
            }
        };

        CassetteContentProvider.getContent().then(json => {
            this.setStateAccordingly("cassettes",json['cassettes']);
        })

        TestContentProvider.getContent().then(json => {
            if(this.mounted)
                this.setState({data:json});
            else
                this.state.data = json;
        })
    }

    getTasks(defaultTasks = []){
        if(!this.state.data) return []
        let allTasks = this.state.data['tasks'] ?? defaultTasks;
        let finalTasks = [];

        allTasks.forEach(task => {
            if(task['only_for_cassette']){
                if(task['only_for_cassette'].includes(this.state.cassette)){
                    finalTasks.push(task);
                }
            }else{
                finalTasks.push(task);
            }
        })

        return finalTasks;
    }

    renderWithProgress(progress, sections, sectionsLoading, lang) {

        if(this.state.data === null)
            return <Loading/>
        else {
            let task = this.getActiveTask();
            let tasks = this.getTasks();
            if (this.state.activeTask === -1) return this.firstPage(progress, sections, sectionsLoading, lang);
            else if (this.state.activeTask === tasks.length) return this.finishedPage(progress, sections, sectionsLoading, lang)
            else if (task.cassette) return this.cassetteTaskScreen(progress, sections, sectionsLoading, lang);
            else return this.taskPage(progress, sections, sectionsLoading, lang);
        }
    }

    handleClickIncorrect(){
        let task = this.getActiveTask();
        if(task) {
            if (this.state.activeStep === task['steps'].length)
                return;
            this.setState({incorrect: true, correct: false})
        }
    }

    handleClickCorrect(e, timeoutPassed = false){
        if(e)
            e.stopPropagation();
        let step = this.getActiveStep()
        if(step['mousedown.timeout'] && !timeoutPassed)
            return;

        let task = this.getActiveTask();
        this.setState(prev=>({
            correct:true,
            incorrect:false,
            tempScreen: null,
            canGoNext:prev.activeStep === task['steps'].length - 1,
            activeStep: prev.activeStep+1
        }), ()=>{
            /* istanbul ignore next */
            let step = this.getActiveStep();
            /* istanbul ignore next */
            if(step) {
                if (step['timeout']) {
                    setTimeout(this.handleClickCorrect, step['timeout'])
                }
            }
        })
    }

    handleMouseDown(){
        let step = this.getActiveStep()
        if(step["mousedown.screenTo"]){
            let id = setTimeout(this.handleClickCorrect,step['mousedown.timeout'], null, true)

            this.setState({
                tempScreen: step["mousedown.screenTo"],
                actionTimeoutID: id
            })
        }
    }

    handleMouseUp(){
        let step = this.getActiveStep()
        if(step["mousedown.screenTo"]){
            if(this.state.actionTimeoutID){
                clearTimeout(this.state.actionTimeoutID)
            }
            this.setState({
                tempScreen: null,
                actionTimeoutID: null
            })
        }
    }

    changeTask(toTask){
        this.setState(prev => ({activeTask: toTask(prev.activeTask), canGoNext:false, correct:false, incorrect:false, activeStep: 0}));
    }

    /**
     * Next slide
     */
    goNext(progress){
        if(!this.state.canGoNext || !progress)
            return;

        if(this.state.activeTask === this.getTasks().length - 1){
            progress.setTutorialLearnt(true);
        }
        this.changeTask(prev => prev + 1);
    }

    /**
     * Prev slide
     */
    goPrev(){
        this.changeTask(prev => prev - 1);
    }

    /**
     * Render the first page of the test
     * @param progress
     * @param sections
     * @param sectionsLoading
     * @param lang
     * @return {JSX.Element}
     */
    firstPage(progress, sections, sectionsLoading, lang){
        return (
            <React.Fragment>
                <div className="test-page-wrapper">
                    <div className="logo">
                        <img src="/images/cardinalhealth-logo.png" alt="logo"/>
                    </div>
                    <h1 dangerouslySetInnerHTML={{__html: lang.t(this.state.data.title)}}/>
                    <div className="subheadline">
                        <p>{lang.t(this.state.data['subtitle'])}</p>
                    </div>
                    <div className="omnipump test-start-scene">
                        <div className="omnipump-machine">
                            <img src={CONSTANTS.image("OmniPumpFront_OffButtons.png")} alt="machine"/>
                        </div>

                        <div className="omnipump-screen">
                            <img src={ScreenImageHelper.getScreen("black",lang)} alt="screen"/>
                        </div>
                    </div>
                    <div className="test-start-button">
                        <Button text={lang.t(this.state.data['button_title'])} next={true} onClick={()=>this.goNext(progress)}/>
                    </div>
                    <div className="body-copy">
                        <p>{lang.t(this.state.data['body'])}</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    /**
     * Render on task page
     * @param progress
     * @param sections
     * @param sectionsLoading
     * @param lang
     * @return {JSX.Element}
     */
    taskPage(progress, sections, sectionsLoading, lang){
        let task = this.getActiveTask();
        let step = this.getActiveStep();
        if(!task){
            return <div/>
        }
        let screen;
        if(this.state.tempScreen){
            screen = this.state.tempScreen;
        }else {
            if (this.state.activeStep === 0) {
                screen = task.screen;
                if (!screen)
                    screen = "green";
            } else {
                if(task['steps'][this.state.activeStep - 1]['screenTo_thick'] && this.state.cassette && this.state.data['thick'] && this.state.data['thick'][this.state.cassette] == true) {
                    screen = task['steps'][this.state.activeStep - 1]['screenTo_thick'];
                } else {
                    screen = task['steps'][this.state.activeStep - 1]['screenTo'];
                }
            }
        }

        screen = this.getScreen(screen);

        let taskHandler = "";
        if(step)
            taskHandler = <div
                onClick={this.handleClickCorrect}
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
                onTouchStart={this.handleMouseDown}
                onTouchEnd={this.handleMouseUp}
                onTouchCancel={this.handleMouseUp}
                className={`click_target ${step.position}`}/>

        return (
          <React.Fragment>
              <div className="progress-indicator clearfix test-question-screen" onClick={this.handleClickIncorrect}>
                  <div className="body-copy">
                      <h3 dangerouslySetInnerHTML={{__html: lang.t(task.title)}}/>
                      {task.instruction ? <p>{lang.t(task.instruction)}</p> : ""}
                  </div>

                  <div className="omnipump">
                      <div className="omnipump-machine">
                          <img src={CONSTANTS.image("OmniPumpFront_OffButtons.png")} alt="machine"/>
                      </div>

                      {this.getLedImage()}
                      {this.getCassetteImage()}
                      {this.getOnScreenNumber(progress)}

                      <div className="omnipump-screen">
                          <img src={ScreenImageHelper.getScreen(screen, lang)} alt="screen"/>
                      </div>
                      {taskHandler}
                  </div>
              </div>

              <div className="answer">
                  {this.getAnswerIndicator(lang)}
              </div>

              <div className="progress-bar">
                  {this.getTasks().map((task, i) => (<span className={`progress-dot dot-with-number ${this.state.activeTask >= i ? "active" : ""}`} key={i} >{i+1}</span>))}
              </div>

              {this.buttons(lang, progress)}
          </React.Fragment>
        );
    }

    getOnScreenNumber(){
        let task = this.getActiveTask();

        let onc = this.getDataFromStep(task, "onscreenNumber","onscreenNumberTo");
        //handle test question timing(question 8)
        if(!task || (task.title === "How do you pause the current feeding regimen for six minutes?" && this.state.activeStep !== 0 && this.state.activeStep !== 4)) {
            return null;
        }

        return generateOnScreenNumber({onscreenNumber: onc}, this.state, (val) => {
            if(onc['saveTo']) {
                this.setState(prev => (
                    {
                        settings: {
                            ...prev.settings,
                            [onc['saveTo']]: val
                        }
                    }
                ))
            }
        });
    }

    cassetteTaskScreen(progress, sections, sectionsLoading, lang){
        let task = this.getActiveTask();

        return (
            <React.Fragment>
                <div className="progress-indicator clearfix test-question-screen" >
                    <div className="body-copy">
                        <h3>{lang.t(task ? task.title : "")}</h3>
                        {task && task.instruction ? <p>{lang.t(task.instruction)}</p> : ""}
                        {task && task.disclaimer ? <p><span>{lang.t(task.disclaimer)}</span></p> :""}
                    </div>
                    {this.cassetteList(lang)}
                </div>
                {this.buttons(lang, progress)}
                <div className="progress-bar">
                  {this.getTasks().map((task, i) => (<span className={`progress-dot dot-with-number ${this.state.activeTask >= i ? "active" : ""}`} key={i} >{i+1}</span>))}
              </div>
            </React.Fragment>
        );
    }

    buttons(lang, progress){
        return (
            <div className="parts-slide-buttons">
                {this.state.activeTask > 0 ? <Button text={lang.t("Previous")} next={false} onClick={this.goPrev}/> : ""}
                <Button text={lang.t("Next")} next={true} onClick={()=>this.goNext(progress)} active={this.state.canGoNext}/>
            </div>
        )
    }


    finishedPage(progress, sections, sectionsLoading, lang){
        const downloadUrl = `/cert/cert-${lang.lang}.pdf`;

        return (
          <div className="progress-indicator clearfix">
              <div className="progress-indicator__img quiz-certificate">
                  <div className="kangaroo-background">
                      <img src={CONSTANTS.image("campfire-background.png")} alt="background"/>
                  </div>
                  <div className="progress-indicator__img-desc">
                      <div className="checkmark"><img src={CONSTANTS.image("checkmark.png")} alt="checkmark"/></div>
                      <h4 dangerouslySetInnerHTML={{__html: lang.t(this.state.data['last_section']['title'])}}/>
                      <p dangerouslySetInnerHTML={{__html: lang.t(this.state.data['last_section']['body'])}}/>
                      <div className="button button-white">
                          <div className="button__text">
                              <a href={downloadUrl} download>{lang.t(this.state.data['last_section']['button_text'])}
                                  <img src={CONSTANTS.image("red-right-arrow.png")} alt="arrow"/>
                              </a>
                          </div>
                      </div>
                      <div className="more-info">
                          <p>{lang.t("Before using the Kangaroo OMNI™, review all relevant package information, including the label and the Instructions for Use.")}</p>
                          <p>
                              {lang.t("For more information about the Kangaroo OMNI™ Enteral Feeding Pump or other Kangaroo™ Enteral Feeding Pumps, visit")} <a href="#">mykangaroolearning.com</a>
                          </p>
                          <p>{lang.t("©2024 Cardinal Health. All Rights Reserved")}</p>
                      </div>

                  </div>
              </div>
          </div>
        );
    }

    getAnswerIndicator(lang){
        let image = "";
        let clazz = "";
        let text = "";

        if(this.state.correct) {
            image = CONSTANTS.image("checkmark.png");
            clazz = "answer-correct";
            text = "Correct";
        }else if(this.state.incorrect){
            image = CONSTANTS.image("incorrect.png");
            clazz = "answer-incorrect";
            text = "Incorrect"
        }else{
            return "";
        }

        return (<div className={clazz}><img src={image} alt="answer"/><p>{lang.t(text)}</p></div>)
    }

    /**
     * Get the current step
     * @return {{position:String, screenTo:String}}
     */
    getActiveStep(){
        let task = this.getActiveTask();
        if(task)
            return task['steps'][this.state.activeStep];
        return {position: "", screenTo: ""};
    }

    /**
     * Get the task the user is on
     * @return {{title: String, disclaimer: String, instruction: String, position: String, screenTo: String, screen: String, cassette: String, right_cassette_answer: String, right_cassette_answer_2: String }|null}
     */
    getActiveTask(){
        if(this.state.activeTask > -1){
            return this.getTasks()[this.state.activeTask];
        }else{
            return null;
        }
    }

    getScreen(data){
        if(typeof data === 'string')
            return data;
        if(!this.state.cassette || !data)
            return ""
        else
            return data[this.state.cassette];
    }

    cassetteList(lang){
        let cassettes = CassetteHelper.getCassettesForLanguage(this.state.cassettes,lang);
        let task = this.getActiveTask();

        return (
            <React.Fragment>
                <div className="feeding-sets knowledge-test-sets clearfix">
                    <EqualHeight>
                        {Object.keys(cassettes).map((cassetteID, i)=>(
                            <div
                                onClick={()=>{
                                    this.setState({cassette:cassetteID, canGoNext: task.right_cassette_answer === cassetteID || task.right_cassette_answer_2 === cassetteID, incorrect: task.right_cassette_answer !== cassetteID && task.right_cassette_answer_2 !== cassetteID, correct: task.right_cassette_answer === cassetteID || task.right_cassette_answer_2 === cassetteID });
                                }}
                                className={`fs-item ${cassetteID === this.state.cassette ? "active" : ""}`}
                                key={`cassette-${i}`}>
                                <EqualHeightElement name={'fs-item'}>
                                    <p>{lang.t(cassettes[cassetteID].title)}</p>
                                </EqualHeightElement>
                                <img src={CassetteImageHelper.image(cassetteID)} alt="cassette"/>
                            </div>
                        ))}
                    </EqualHeight>
                </div>
                <div className="answer">
                  {this.getAnswerIndicator(lang)}
                </div>
            </React.Fragment>
        );
    }

    getCassetteImage(){
        let cassetteID = this.state.cassette
        if(cassetteID) {
            let cassette = CassetteImageHelper.image(cassetteID);

            return (
                <div className="omnipump-cassette">
                    <img src={cassette} alt="cassette"/>
                </div>
            )
        }else{
            return "";
        }
    }

    getLedImage(){
        let task = this.getActiveTask();
        let led = this.getDataFromStep(task, "led", "ledTo");

        if(led)
            return  <div className="omnipump-led" key={led}><img src={LedImagesHelper.image(led)} alt="leds"/></div>
        else
            return "";
    }


    getDataFromStep(task, key, toKey){
        if(task == null)
            return null;
        let onc;
        if(this.state.activeStep === 0) {
            onc = task[key];
        }else {
            onc = task['steps'][this.state.activeStep - 1][toKey];
            if(!onc)
                onc = task[key];
        }

        return onc;
    }
}
