/**
 * Define a base for all content providers
 */
export default class AbstractContentProvider{
    static contentCache = {};

    /**
     * Fetch the url and return the content as a promise
     * @return Promise<Object|Array>
     */
    static async getContent(){
        return this.getContentFromURL(this.getURL())
    }

    /**
     *
     * @param url
     * @return {Promise<{}|any>}
     */
    static async getContentFromURL(url){
        if(this.contentCache[url])
            return this.contentCache[url];

        try {
            let resp = await fetch(url);
            if (resp.ok){
                let text = await resp.text();
                this.contentCache[url] = text !== "" ? JSON.parse(text) : "";
                return this.contentCache[url];
            }else{
                // for test
            }
        }catch (e){
            console.log(e);
        }

        return {};
    }

    /**
     * Get the url to load from
     * @return string
     */
    static getURL(){
        throw new Error("getURL must be implemented")
    }
}